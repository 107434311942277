<template>
    <i18n path="scenario-group-index-styled" class="flex flex-row items-center text-gray-400 font-light" tag="div">
        <template #scenarioGroupAbbreviation>
            <Tooltip>
                <template #trigger="tooltipProps">
                    <span
                        class="font-light text-gray-400 underline decoration-dashed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 rounded tabular-nums"
                        :aria-labelledby="tooltipProps['aria-labelledby']"
                        tabindex="0"
                    >
                        {{ $t('scenario-group-abbreviated') }}
                    </span>
                </template>

                <template #text>{{ $t('scenario-group-full-text') }}</template>
            </Tooltip>
        </template>

        <template #scenarioGroupIndex>
            <span class="tabular-nums">
                <span class="font-light text-gray-400">
                    {{ '0'.repeat(props.maxDigits - indexDigitsCount) }}
                </span>
                <span class="font-medium text-gray-700">{{ String(props.index) }}</span>
            </span>
        </template>
    </i18n>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type ScenarioGroupIndexProps = {
    index: number;
    maxDigits: number;
};

const props = defineProps<ScenarioGroupIndexProps>();

const indexDigitsCount = computed(() => String(props.index).length);
</script>
