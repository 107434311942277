<template>
    <article class="bg-gray-50 rounded-lg shadow" :aria-labelledby="headlineId">
        <header>
            <h2 :id="headlineId" class="sr-only">{{ props.invisibleHeadline }}</h2>
        </header>

        <div>
            <slot />
        </div>
    </article>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';

type HeadlessCardProps = {
    invisibleHeadline: string;
};

const props = defineProps<HeadlessCardProps>();

const headlineId = nanoid();
</script>
