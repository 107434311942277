<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>{{ $t('project-wiki') }}</template>
        <template #icon>
            <ConstructionIcon class="w-12 h-12" />
        </template>
        <template #buttons>
            <Button
                variant="Info-Strong"
                size="Base"
                :disabled-explanation="createProjectSeriesDisabledExplanation"
                @click="goToCreateProjectSeries()"
            >
                <template #icon><PlusIcon /></template>
                {{ $t('project-series-create') }}
            </Button>
        </template>
        <template #body>
            <div class="space-y-4">
                <FlexibleCard
                    v-for="series in allSeries"
                    :key="series.id"
                    headline-id="projectSeries"
                    expandable-status="Not-Collapsible"
                >
                    <template #header="{ headlineId }">
                        <CardHeaderStatic>
                            <div class="grid grid-cols-3 items-center">
                                <CardHeadline :headline-id="headlineId">
                                    <TextLink :to="getProjectSeriesLink(series.id)">{{ series.nameShort }}</TextLink>
                                </CardHeadline>

                                <Tooltip v-if="series.singleProject" :id="singleProjectTooltipId">
                                    <template #trigger>
                                        <SingleEventIcon
                                            :aria-labelledby="singleProjectTooltipId"
                                            class="w-6 h-6 text-gray-700"
                                        />
                                    </template>
                                    <template #text>{{ $t('limited-to-single-project') }}</template>
                                </Tooltip>
                                <div v-else class="w-6 h-6"></div>
                                <Badge
                                    v-if="getSeriesStatus(series.projects?.nodes)"
                                    :tooltip-text="$t('project-series-status-calculated')"
                                    size="Extra-Small"
                                    :variant="getSeriesBadgeVariant(series)"
                                >
                                    {{ getSeriesBadgeLabel(series) }}
                                </Badge>
                                <span v-else class="text-gray-500 text-sm">({{ $t('includes-no-projects') }})</span>
                            </div>
                        </CardHeaderStatic>
                    </template>
                </FlexibleCard>
            </div>
        </template>
    </HeaderCardsLayout>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Badge from '@/components/Badge/Badge.vue';
import Button from '@/components/Button/Button.vue';
import CardHeaderStatic from '@/components/Card/CardHeaderStatic.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import ConstructionIcon from '@/components/Icon/ConstructionIcon.vue';
import PlusIcon from '@/components/Icon/PlusIcon.vue';
import SingleEventIcon from '@/components/Icon/SingleEventIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import TextLink from '@/components/TextLink/TextLink.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { GetAllProjectSeriesDocument } from '@/generated/graphql';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed } from 'vue';
import {
    getProjectSeriesLink,
    getSeriesBadgeLabel,
    getSeriesBadgeVariant,
    getSeriesStatus,
    goToCreateProjectSeries,
} from '../project';

const fluent = useFluent();
useHeadSafe({ title: () => fluent.$t('project-wiki') });

const createProjectSeriesDisabledExplanation = computed(() =>
    auth.isAdmin() ? undefined : fluent.$t('no-permission-to-create'),
);

const getAllProjectSeries = useQuery(GetAllProjectSeriesDocument);
const allSeries = computed(() => getAllProjectSeries.result.value?.projectSerieses?.nodes);

const singleProjectTooltipId = nanoid();
</script>
