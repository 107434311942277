import { GetScenarioGroupQuery, ScenarioGroupDetailsFragment, UpdateScenarioGroupDocument } from '@/generated/graphql';
import { ApolloQueryResult } from '@apollo/client';
import { useMutation } from '@vue/apollo-composable';
import { ref, Ref, toValue, watch } from 'vue';
import { ScenarioGroupSchema, ScenarioGroupSchemaType } from '../forms/schemas';
import { ScenarioGroupErrors, ScenarioGroupFields } from '../types';

function setEmptyErrors() {
    return {
        nameShort: [],
        nameLong: [],
        intention: [],
        description: [],
        kapmanNumber: [],
        companyGroup: [],
        businessDomain: [],
        department: [],
        responsible: [],
        organisationalChanges: [],
    };
}

function setEmptyFields() {
    return {
        nameShort: '',
        nameLong: '',
        intention: '',
        description: '',
        kapmanNumber: '',
        companyGroup: '',
        businessDomain: '',
        department: '',
        responsible: '',
    };
}

export function useScenarioGroupForm(
    scenarioGroup: Ref<ScenarioGroupDetailsFragment>,
    refetch: () => Promise<ApolloQueryResult<GetScenarioGroupQuery>> | undefined,
    resetActiveCard: () => void,
) {
    const schema = ScenarioGroupSchema;
    const fields: Ref<ScenarioGroupFields> = ref(setEmptyFields());
    const errors: Ref<ScenarioGroupErrors> = ref(setEmptyErrors());
    const formTouched: Ref<boolean> = ref(false);

    watch(scenarioGroup, (data) => {
        fields.value = {
            nameShort: data.nameShort,
            nameLong: data.nameLong,
            intention: data.intention,
            description: data.description,
            kapmanNumber: data.kapmanNumber,
            companyGroup: data.companyGroup,
            businessDomain: data.businessDomain,
            department: data.department,
            responsible: data.responsible,
        };
    });

    const updateScenarioGroup = useMutation(UpdateScenarioGroupDocument);

    function isFieldTouched(key: string) {
        if (scenarioGroup) {
            const actualValue = scenarioGroup[key as keyof GetScenarioGroupQuery['scenarioGroup']];
            const newValue = fields.value[key];
            return actualValue !== newValue;
        }
    }

    function isFormTouched() {
        const fieldKeys = schema.keyof().options;
        if (fieldKeys.some((f) => isFieldTouched(f))) {
            formTouched.value = true;
        } else {
            formTouched.value = false;
        }
    }

    function onInput(fieldData: { event: Event; fieldName: string }) {
        const { event, fieldName } = fieldData;
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const key = fieldName as keyof ScenarioGroupFields;
        const errorKey = fieldName as keyof ScenarioGroupErrors;
        const schemaKey = fieldName as keyof ScenarioGroupSchemaType;
        const parse = schema.shape[schemaKey].safeParse(value);
        if (parse.success) {
            errors.value[errorKey] = [];
            fields.value[key] = parse.data ?? '';
        } else {
            errors.value[errorKey] = parse.error.format()._errors;
            fields.value[key] = value;
        }
        isFormTouched();
    }

    async function discardChanges() {
        await refetch();
        resetActiveCard();
    }

    async function onUpdateScenarioGroup() {
        const fieldValues = fields.value;
        const parse = schema.safeParse(fieldValues);
        if (parse.success) {
            const params = { id: toValue(scenarioGroup).id, ...fieldValues };
            await updateScenarioGroup.mutate(params);
        }
    }

    return {
        fields,
        formTouched,
        errors,
        onInput,
        onUpdateScenarioGroup,
        discardChanges,
    };
}
