<template>
    <div v-if="series" class="space-y-4">
        <FlexibleCard headline-id="allocationRules" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('allocationRules')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('allocationRules')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'allocationRules', label: $t('allocation-rules') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'allocationRules')"
                    @save="$emit('save', 'allocationRules')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('allocation-rules') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <AllocationRulesForm
                        v-if="showForm('allocationRules')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('allocation-rules')"
                            :text="valueToString(series.allocationRules)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('location-history')"
                            :text="valueToString(series.locationHistory)"
                            :help-text="$t('location-history-help')"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="allocationRulesLocationTypes" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('allocationRulesLocationTypes')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('allocationRulesLocationTypes')"
                    @enter-edit-mode="
                        $emit('enterEditMode', {
                            id: 'allocationRulesLocationTypes',
                            label: $t('allocation-rules-location-types'),
                        })
                    "
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'allocationRulesLocationTypes')"
                    @save="$emit('save', 'allocationRulesLocationTypes')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('allocation-rules-location-types') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <AllocationNeedsForm
                        v-if="showForm('allocationRulesLocationTypes')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('notes-hall-exhibition-areas')"
                            :text="valueToString(series.notesHallExhibitionAreas)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-boulevard-sections')"
                            :text="valueToString(series.notesBoulevardSections)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-passages')"
                            :text="valueToString(series.notesPassages)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-entrances')"
                            :text="valueToString(series.notesEntrances)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-service-centers')"
                            :text="valueToString(series.notesServiceCenters)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-conference-rooms')"
                            :text="valueToString(series.notesConferenceRooms)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-offices-internal')"
                            :text="valueToString(series.notesOfficesInternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-storage-internal')"
                            :text="valueToString(series.notesStorageInternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-offices-external')"
                            :text="valueToString(series.notesOfficesExternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-storage-external')"
                            :text="valueToString(series.notesStorageExternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-areas')"
                            :text="valueToString(series.notesAreas)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-parking')"
                            :text="valueToString(series.notesParking)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('other-allocation-notes')"
                            :text="valueToString(series.otherAllocationNeeds)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import AllocationNeedsForm from '@/event/forms/series/AllocationNeedsForm.vue';
import AllocationRulesForm from '@/event/forms/series/AllocationRulesForm.vue';
import { EventSeriesDetails, EventSeriesDetailsErrors } from '@/event/types';
import { valueToString } from '@/forms/forms';
import { computed } from 'vue';

const props = defineProps<{ series: EventSeriesDetails; errors: EventSeriesDetailsErrors; activeCard: ActiveCard }>();

defineEmits<{
    (e: 'enterEditMode', v: { id: string; label: string }): void;
    (e: 'leaveEditMode'): void;
    (e: 'save', v: string): void;
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
    (e: 'discardChanges', v: string): void;
}>();

const activeCard = computed(() => props.activeCard);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard);
</script>
