<!-- TODO Fix hovering borders -->
<!-- TODO Increase size of icons -->
<template>
    <Tooltip class="h-full min-w-full">
        <template #trigger>
            <button
                class="border border-transparent focus-visible:outline-none active:border-solid flex place-items-center place-content-center h-full w-full"
                :class="[
                    backgroundColorClass,
                    borderColorClass,
                    textColorClass,
                    {
                        '-mt-px h-[calc(100%+1px)]': !props.isFullHeight,
                        'cursor-not-allowed': props.status === 'Loading',
                    },
                ]"
                :aria-disabled="props.status === 'Loading'"
                type="button"
                @click="clickButton"
            >
                <PlusIcon v-if="props.status === 'Create'" class="size-3" />
                <PendingIcon v-if="props.status === 'Loading'" class="size-3 animate-spin" />
                <RefreshIcon v-if="props.status === 'Retry'" class="size-3" />
            </button>
        </template>

        <template v-if="Boolean(props.currentAllocationVariantLabel)" #header>
            {{ props.currentAllocationVariantLabel }}
        </template>

        <template #text>
            <div v-if="props.status === 'Create'" class="min-w-max">{{ $t('click-to-create-allocation') }}</div>
            <div v-if="props.status === 'Loading'" class="min-w-max">{{ $t('creating-allocation') }}</div>
            <div v-if="props.status === 'Retry'" class="min-w-max">{{ $t('creating-allocation-failed') }}</div>
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue';
import PendingIcon from '../../Icon/PendingIcon.vue';
import PlusIcon from '../../Icon/PlusIcon.vue';
import RefreshIcon from '../../Icon/RefreshIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import { triggerHideTooltipKey } from '../../Tooltip/injectionKeys';

type FullHeightCreateButtonProps = {
    isFullHeight: boolean;
    status: 'Create' | 'Loading' | 'Retry';
    currentAllocationVariantLabel?: string;
};

const props = defineProps<FullHeightCreateButtonProps>();

const emit = defineEmits<{
    (e: 'click'): void;
}>();

const backgroundColorClass = computed(() => {
    switch (props.status) {
        case 'Create':
            return 'hover:bg-emerald-50 focus-visible:bg-emerald-50 active:bg-emerald-100';

        case 'Loading':
            return 'hover:bg-gray-200 focus-visible:bg-gray-200 active:bg-graymerald-300';

        case 'Retry':
            return 'hover:bg-red-50 focus-visible:bg-red-50 active:bg-red-100';
    }
});

const borderColorClass = computed(() => {
    switch (props.status) {
        case 'Create':
            return `
                hover:border hover:border-dashed hover:border-emerald-700
                focus-visible:border focus-visible:border-dashed focus-visible:border-emerald-700
            `;

        case 'Loading':
            return ``;

        case 'Retry':
            return `
                hover:border hover:border-dashed hover:border-red-700
                focus-visible:border focus-visible:border-dashed focus-visible:border-red-700
            `;
    }
});

const textColorClass = computed(() => {
    switch (props.status) {
        case 'Create':
            return 'text-gray-300 hover:text-emerald-700 focus-visible:text-emerald-700';

        case 'Loading':
            return 'text-gray-700';

        case 'Retry':
            return 'text-red-700';
    }
});

function clickButton() {
    if (props.status !== 'Loading') {
        triggerHideTooltip();
        emit('click');
    }
}

// This is used to force close the Tooltip after clicking the button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}
</script>
