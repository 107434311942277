<template>
    <div ref="wrapperRef" class="flex">
        <Tooltip :is-disabled="props.status === 'Focused' || props.status === 'Open'">
            <template #trigger="tooltipProps">
                <button
                    v-bind="tooltipProps"
                    class="inline-flex flex-row gap-[0.0625rem] relative outline-none z-10"
                    :class="[
                        props.isEditable ? 'cursor-pointer' : 'cursor-not-allowed',
                        {
                            'wrapper-border wrapper-border-gray-700': props.status === 'Focused',
                            'wrapper-border wrapper-border-gray-50': props.status === 'Default',
                            'bg-gray-50': props.status !== 'Open',
                            'rounded-l-full before:rounded-l-full':
                                props.form === 'Start-End' || props.form === 'Start-Middle',
                            'rounded-r-full before:rounded-r-full':
                                props.form === 'Start-End' || props.form === 'Middle-End',
                        },
                    ]"
                    :aria-disabled="!props.isEditable"
                    type="button"
                >
                    <slot />
                </button>
            </template>

            <template v-if="Boolean(props.allocationLabel)" #header>
                <div :class="{ 'text-gray-700': !props.isEditable }">
                    {{ props.allocationLabel }}
                </div>
            </template>

            <template #text>
                <div class="w-max" :class="{ 'text-gray-500': !props.isEditable }">
                    {{ props.isEditable ? $t('click-to-edit-allocation') : $t('allocation-cannot-be-edited') }}
                </div>
            </template>
        </Tooltip>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Tooltip from '../../Tooltip/Tooltip.vue';

type AllocationWrapperProps = {
    status: 'Default' | 'Focused' | 'Open';
    allocationLabel?: string;
    isEditable: boolean;
    form: 'Start-End' | 'Start-Middle' | 'Middle' | 'Middle-End';
};

const props = defineProps<AllocationWrapperProps>();

const wrapperRef = ref();
defineExpose({
    wrapperRef,
});
</script>

<style scoped>
.wrapper-border::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
}

.wrapper-border-gray-700::before {
    border: 1px solid theme('colors.gray.700');
}

.wrapper-border-gray-50::before {
    border: 1px solid theme('colors.gray.50');
}
</style>
