import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import {
    Conflict,
    ConflictMarker,
    ConflictStatus,
    EnrichedAllocation,
    Location,
    LocationType,
    LocationWithAllDayProperties,
    LocationWithDays,
    Section,
} from '../Timeline.types';
import {
    TimelineDayWithLayoutedAllocations,
    TimelineDayWithNotLayoutedAllocations,
    TimelineLocation,
    TimelineRow,
    TimelineSection,
    VisibleAllocationStart,
} from '../TimelineView.types';
import { getIcon, getIconTitle } from '../logic/icons';
import { calculateAllocationStartsPerDay } from './allocations';
import { extractRowsFromLocations } from './cells';
import { calculateConflictMarkerForDay, calculateConflictStatusForDay } from './conflicts';
import { insertInvisibleAllocations } from './continuingAllocationsLayout';

export function flattenLocations(locations: LocationWithAllDayProperties[], sectionId?: string): LocationWithDays[] {
    let flattenedLocations: LocationWithDays[] = [];

    for (let locationIndex = 0; locationIndex < locations.length; locationIndex++) {
        const location = locations[locationIndex];

        const hasNestedLocations = location.nestedLocations.length > 0;
        const isTemporaryLeaf = (hasNestedLocations && !location.isExpanded) || location.isActualLeaf;
        const showUnresolvedConflictsIcon = location.hasUnresolvedConflicts && isTemporaryLeaf;
        const showResolvedConflictsIcon =
            !location.hasUnresolvedConflicts && location.conflictsResolvedCount > 0 && isTemporaryLeaf;

        let status: 'has-conflicts-strong' | 'has-conflicts-light' | 'invalid-data' | 'ok' = 'ok' as const;

        if (isTemporaryLeaf && location.conflictsUnresolvedCount > 0) {
            status = 'has-conflicts-strong' as const;
        } else if (!isTemporaryLeaf && location.conflictsUnresolvedCount > 0) {
            status = 'has-conflicts-light' as const;
        } else if (location.areNestedLocationsMissing) {
            status = 'invalid-data' as const;
        }

        flattenedLocations = [
            ...flattenedLocations,
            {
                ...location,
                hasNestedLocations,
                isTemporaryLeaf,
                showUnresolvedConflictsIcon,
                showResolvedConflictsIcon,
                days: location.days.map((day) => {
                    if (!showUnresolvedConflictsIcon) {
                        return {
                            ...day,
                            conflictMarker: 'no-conflict',
                            hasCreateButton: location.hasCreateButtons,
                        };
                    }

                    return {
                        ...day,
                        hasCreateButton: location.hasCreateButtons,
                    };
                }),
                sectionId: sectionId ?? location.sectionId,
                status,
                backgroundClass:
                    isTemporaryLeaf && location.conflictsUnresolvedCount > 0
                        ? 'bg-red-600'
                        : !isTemporaryLeaf && location.conflictsUnresolvedCount > 0
                          ? 'bg-red-100'
                          : 'bg-gray-50',
                rowId: `row-${location.id}`,
            },
        ];

        if (location.isExpanded && location.nestedLocations) {
            flattenedLocations = [
                ...flattenedLocations,
                ...flattenLocations(location.nestedLocations, location.sectionId),
            ];
        }
    }

    return flattenedLocations;
}

function countContinuousAggregationCellSpans(
    hasAggregationMap: Map<number, boolean>,
    startIndex: number,
    durationInDays: number,
): number {
    let count = 0;
    let index = startIndex;

    while (startIndex < durationInDays) {
        if (hasAggregationMap.get(index) !== true) {
            break;
        }

        index++;
        count++;
    }

    return count;
}

export function calculateDayProperties(
    locations: Location[],
    allocations: EnrichedAllocation[],
    conflicts: Conflict[],
    timelineStartDateTime: DateTime,
    timelineEndDateTime: DateTime,
    durationInDays: number,
    hasTimelineCreateButtons: boolean,
): LocationWithAllDayProperties[] {
    const locationsWithDays = locations.map((location) => {
        const locationIcon = getIcon(location.typeCategory, location.type);
        const locationIconTitle = getIconTitle(location.typeCategory, location.type);

        const allConflictsInLocation = conflicts.filter((conflict) =>
            isLocationOrNestedLocation(location, conflict.locationId),
        );
        const unresolvedConflictsInLocation = allConflictsInLocation.filter(
            (conflict) => conflict.status === 'unresolved',
        );

        let daysArrayInput: Pick<
            TimelineDayWithNotLayoutedAllocations,
            'isWorkDay' | 'conflictMarker' | 'allocationStarts' | 'startDateTime'
        >[] = [];

        // Are there allocations in this location or in a nested location?
        const allocationsInLocation = allocations.filter((allocation) =>
            isLocationOrNestedLocation(location, allocation.locationId),
        );

        // Required as an intermediate step to calculate the aggregations
        const isDayOccupied: Map<number, boolean> = new Map();
        const allocationsPerDay: Map<number, number> = new Map();
        let isInsideAggregation = false;

        for (let day = 0; day < durationInDays; day++) {
            const dateTime = timelineStartDateTime.plus({ days: day });

            // Calculate conflicts
            const conflictStatus: ConflictStatus = calculateConflictStatusForDay(allConflictsInLocation, dateTime);
            const conflictMarker: ConflictMarker = calculateConflictMarkerForDay(
                unresolvedConflictsInLocation,
                dateTime,
                conflictStatus,
            );

            // Calculate allocation starts
            const allocationStartsInLocationAndNestedLocations: VisibleAllocationStart[] =
                calculateAllocationStartsPerDay(
                    allocationsInLocation,
                    dateTime,
                    timelineStartDateTime,
                    timelineEndDateTime,
                );

            daysArrayInput = [
                ...daysArrayInput,
                {
                    isWorkDay: dateTime.weekday !== 6 && dateTime.weekday !== 7,
                    conflictMarker,
                    allocationStarts: allocationStartsInLocationAndNestedLocations,
                    startDateTime: dateTime,
                },
            ];

            // Mark days with at least 1 allocation in any (potentially nested) location
            allocationStartsInLocationAndNestedLocations.forEach((allocation) => {
                for (
                    let dayOffsetWithAllocation = 0;
                    dayOffsetWithAllocation < allocation.visibleCellSpan;
                    dayOffsetWithAllocation++
                ) {
                    isDayOccupied.set(day + dayOffsetWithAllocation, true);

                    // Count allocations per day (required for the create buttons)
                    const allocationsOnThisDay = allocationsPerDay.get(day + dayOffsetWithAllocation) ?? 0;
                    allocationsPerDay.set(day + dayOffsetWithAllocation, allocationsOnThisDay + 1);
                }
            });
        }

        // We need this second loop as we need to have all isDayOccupied information for a given location collected before processing them
        for (let day = 0; day < durationInDays; day++) {
            const dateTime = timelineStartDateTime.plus({ days: day });
            const hasDayAggregation = !location.isLeafLocation && isDayOccupied.get(day) === true;

            if (hasDayAggregation) {
                daysArrayInput[day].allocationStarts = [];

                if (!isInsideAggregation) {
                    isInsideAggregation = true;

                    const cellSpanCount = countContinuousAggregationCellSpans(isDayOccupied, day, durationInDays);

                    const isFirstTimelineDay = timelineStartDateTime.hasSame(dateTime, 'day');
                    const hasAllocationsOnLastDay = timelineStartDateTime
                        .plus({ days: cellSpanCount })
                        .hasSame(timelineEndDateTime, 'day');
                    const hasAllocationsStartingBefore =
                        isFirstTimelineDay &&
                        allocationsInLocation.some((allocation) => allocation.startDateTime < dateTime);
                    const hasAllocationsEndingAfter =
                        hasAllocationsOnLastDay &&
                        allocationsInLocation.some((allocation) => {
                            return allocation.endDateTime > dateTime.plus({ days: cellSpanCount });
                        });

                    const allocationStartDateTime = timelineStartDateTime
                        .plus({ days: day })
                        .minus({ days: hasAllocationsStartingBefore ? 1 : 0 });

                    const allocationEndDateTime = allocationStartDateTime
                        .plus({ days: cellSpanCount })
                        .plus({ days: hasAllocationsStartingBefore ? 1 : 0 });

                    daysArrayInput[day].allocationStarts = [
                        {
                            type: 'Visible-Allocation-Start',
                            id: `aggregation-${location.id}-${day}`,
                            allocationType: 'Aggregation',
                            allocationVariantId: 'aggregation',
                            allPhases: [
                                {
                                    id: `aggregation-phase-${nanoid()}`,
                                    startDateTime: allocationStartDateTime,
                                    cellSpan:
                                        cellSpanCount +
                                        (hasAllocationsStartingBefore ? 1 : 0) + // Add simulated day before
                                        (hasAllocationsEndingAfter ? 1 : 0), // Add simulated day after
                                    type: 'Aggregation',
                                    specialization: null,
                                },
                            ],
                            visibleCellSpan: cellSpanCount,
                            label: '',
                            variantLabel: '',
                            groupLabel: '',
                            isEditable: false,
                            linkTarget: '',
                            startDateTime: allocationStartDateTime,
                            endDateTime: hasAllocationsEndingAfter
                                ? timelineEndDateTime.plus({ days: 1 })
                                : allocationEndDateTime,
                            variant: 'Solid',
                        },
                    ];
                }
            } else {
                isInsideAggregation = false;
            }
        }

        // Layout allocations
        const daysArrayLayouted = insertInvisibleAllocations(daysArrayInput as TimelineDayWithNotLayoutedAllocations[]); // TODO Avoid incorrect (!) type assertion. This is a workaround to more easily harmonize the implementation with the external events

        const isInside = getIsInside(location.type);

        const isExpanded = false;

        return {
            ...location,
            isInside,
            sectionId: location.sectionId ?? 'no-section',
            icon: locationIcon,
            iconTitle: locationIconTitle,
            hasNestedLocations: location.nestedLocations.length > 0,
            nestedLocations: calculateDayProperties(
                location.nestedLocations,
                allocations,
                conflicts,
                timelineStartDateTime,
                timelineEndDateTime,
                durationInDays,
                hasTimelineCreateButtons,
            ),
            areNestedLocationsMissing: !location.isLeafLocation && location.nestedLocations.length === 0,
            isExpanded,
            isActualLeaf: location.isLeafLocation,
            conflictsResolvedCount: allConflictsInLocation.length - unresolvedConflictsInLocation.length,
            conflictsUnresolvedCount: unresolvedConflictsInLocation.length,
            hasUnresolvedConflicts: unresolvedConflictsInLocation.length > 0,
            days: daysArrayLayouted,
            hasCreateButtons: location.isLeafLocation && hasTimelineCreateButtons,
            checkboxStatus: 'No-Checkbox' as const,
        };
    });

    return locationsWithDays;
}

export function getIsInside(locationType: LocationType | undefined) {
    const locationTypesInside = [
        'Hallenausstellungsfläche',
        'Eingang',
        'Boulevard',
        'Passage',
        'Passage inkl. Durchfahrtstor',
        'Büro/Besprecher (ZBV intern)', // ZBV = Zur besonderen Verwendung
        'Büro/Besprecher (ZBV extern)',
        'Lagerraum (ZBV intern)',
        'Lagerraum (ZBV extern)',
        'Konferenzraum',
        'Service-Center',
    ];

    // TODO Extend this to outside areas, too. This only works as long as we have only inside areas
    if (!locationType) {
        return true;
    }

    return locationTypesInside.includes(locationType);
}

export function isLocationOrNestedLocation(location: Location, locationId: string): boolean {
    if (location.id === locationId) {
        return true;
    }

    if (location.nestedLocations.length > 0) {
        return location.nestedLocations.some((nestedLocation) =>
            isLocationOrNestedLocation(nestedLocation, locationId),
        );
    }

    return false;
}

export function groupLocationsBySection(locationsWithDays: LocationWithDays[], sections: Section[]): TimelineSection[] {
    if (sections.length === 0) {
        const rows = extractRowsFromLocations(locationsWithDays);

        return [
            {
                hasLabel: false,
                locations: locationsWithDays.map((location) => ({ ...location, isVisible: true })),
                rows,
            },
        ];
    }

    return sections.map((section) => {
        let rows: TimelineRow[] = [];
        let unresolvedConflictsCount = 0;
        let allocationCount = 0;

        const locations: TimelineLocation[] = locationsWithDays
            .filter((location) => location.sectionId === section.id)
            .map((location, locationIndex) => {
                unresolvedConflictsCount += location.conflictsUnresolvedCount;

                const row = {
                    days: location.days.map((day, dayIndex) => {
                        const allocationStarts = day.allocationStarts.filter(
                            (allocation) => allocation.type === 'Visible-Allocation-Start',
                        );

                        if (allocationStarts.length > 0) {
                            allocationCount = allocationCount + 1;
                        }

                        return {
                            ...day,
                            isWorkDay: day.isWorkDay,
                            conflictMarker: day.conflictMarker,
                            rowId: location.rowId,
                            columnId: `column-${dayIndex}`,
                            isFirstRow: locationIndex === 0,
                            isFirstColumn: dayIndex === 0,
                        } satisfies TimelineDayWithLayoutedAllocations;
                    }),
                    locationId: location.id,
                    isLocationInside: location.isInside,
                };

                rows = [...rows, row];

                return {
                    ...location,
                    isVisible: section.isExpanded,
                };
            });

        return {
            hasLabel: true as const,
            id: section.id,
            status: unresolvedConflictsCount > 0 ? ('has-conflicts' as const) : ('ok' as const),
            unresolvedConflictsCount,
            label: section.label,
            isExpanded: section.isExpanded,
            icon: getIcon(section.typeCategory, section.type),
            iconTitle: getIconTitle(section.typeCategory, section.type),
            locations,
            rows: section.isExpanded ? rows : [],
            allocationCount,
            checkboxStatus: 'No-Checkbox',
        };
    });
}

function getLocationIds(locations: Location[]): string[] {
    return locations.flatMap((location) => {
        let locationIds: string[] = [];

        locationIds = [...locationIds, location.id];

        if (location.nestedLocations.length > 0) {
            locationIds = [...locationIds, ...getLocationIds(location.nestedLocations)];
        }

        return locationIds;
    });
}

function assertUniqueLocationIds(locations: Location[]) {
    const locationIds = getLocationIds(locations);
    const sortedLocationIds = locationIds.sort();

    let previousString: string | undefined;
    for (const currentString of sortedLocationIds) {
        if (currentString === previousString) {
            const uniqueLocationIdsCount = new Set(locationIds).size;
            const totalLocationIdsCount = locationIds.length;

            throw new Error(
                `Duplicate location ID found: "${currentString}" (${uniqueLocationIdsCount} unique/${totalLocationIdsCount} total location IDs)`,
            );
        }
        previousString = currentString;
    }
}

function assertLevelsAreCorrect(locations: Location[], startLevel: number = 0) {
    locations.forEach((location) => {
        if (location.level !== startLevel) {
            throw new Error(
                `Unexpected level ${location.level}, expected ${startLevel} on location with ID ${location.id}`,
            );
        }

        if (location.nestedLocations.length > 0) {
            assertLevelsAreCorrect(location.nestedLocations, startLevel + 1);
        }
    });
}

function getSectionIds(locations: Location[]): string[] {
    return locations.flatMap((location) => {
        let sectionIds: string[] = [];

        if (location.sectionId) {
            sectionIds = [...sectionIds, location.sectionId];
        }

        if (location.nestedLocations.length > 0) {
            sectionIds = [...sectionIds, ...getSectionIds(location.nestedLocations)];
        }

        return sectionIds;
    });
}

function assertSectionIdExists(locations: Location[], sections: Section[] | undefined) {
    if (sections) {
        const usedSectionIds = getSectionIds(locations);

        for (const sectionId of usedSectionIds) {
            if (!sections.find((section) => section.id === sectionId)) {
                throw new Error(
                    `Section ID "${sectionId}" was referenced in a location but there is no corresponding section definition`,
                );
            }
        }
    }
}

function assertChildrenHaveSectionId(locations: Location[], parentSectionId?: string | undefined) {
    locations.forEach((location) => {
        if (parentSectionId) {
            if (location.sectionId !== parentSectionId) {
                throw new Error(
                    `Section ID "${parentSectionId}" was expected on location with ID ${location.id} to be in sync with parent location`,
                );
            }

            if (location.nestedLocations.length > 0) {
                assertChildrenHaveSectionId(location.nestedLocations, parentSectionId);
            }
        } else if (location.sectionId) {
            if (location.nestedLocations.length > 0) {
                assertChildrenHaveSectionId(location.nestedLocations, location.sectionId);
            }
        }
    });
}

export function validateLocations(locations: Location[], sections: Section[] | undefined) {
    assertUniqueLocationIds(locations);
    assertLevelsAreCorrect(locations);
    assertSectionIdExists(locations, sections);
    assertChildrenHaveSectionId(locations);
}
