import { BadgeDropDownVariant } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import router from '@/plugins/router';
import { ScenarioGroupStatus, ScenarioStatus } from './types';

export function linkToScenarioGroup(id: string) {
    return { name: 'scenario-group-scenarios-tab', params: { scenarioGroupId: id } };
}

export function linkToScenario(id: string) {
    return { name: 'scenario-masterdata-tab', params: { scenarioId: id } };
}

export function linkToScenarioOverview() {
    return { name: 'scenarios-overview' };
}

export function goToCreateScenarioGroup() {
    router.push({ name: 'scenario-group-create' });
}

export function goToCreateScenario(scenarioGroupId: string, scenarioGroupName: string, scenarioGroupIndex: number) {
    router.push({ name: 'scenario-create', query: { scenarioGroupId, scenarioGroupName, scenarioGroupIndex } });
}

export function goToScenarioOverview() {
    router.push(linkToScenarioOverview());
}

export function gotToScenarioGroup(id: string) {
    router.push(linkToScenarioGroup(id));
}

export function gotToScenario(id: string) {
    router.push(linkToScenario(id));
}

export function getScenarioGroupBadgeVariant(status: ScenarioGroupStatus): BadgeDropDownVariant {
    switch (status) {
        case 'active':
            return 'Green';
        case 'passive':
            return 'Yellow';
        case 'realized':
            return 'Gray';
        case 'discontinued':
            return 'Red';
        default:
            return 'Green';
    }
}

export function getScenarioBadgeVariant(status: ScenarioStatus): BadgeDropDownVariant {
    switch (status) {
        case 'active-idea':
        case 'active-option':
        case 'active-fix':
            return 'Green';
        case 'passive':
            return 'Yellow';
        case 'discontinued':
            return 'Red';
        default:
            return 'Green';
    }
}

export function getScenarioGroupIndexString(index: number, maxDigits: number = 5) {
    const indexDigitsCount = index.toString().length;
    return `SG-${'0'.repeat(maxDigits - indexDigitsCount)}${index}`;
}

export function getScenarioIndexString(index: number, year: number, maxDigits: number = 5) {
    const indexDigitsCount = index.toString().length;
    return `S${year}-${'0'.repeat(maxDigits - indexDigitsCount)}${index}`;
}
