<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>{{ $t('scenario-group-create') }}</template>
        <template #breadcrumb>
            <Breadcrumb :headline="$t('breadcrumb')" :current-page="$t('scenario-group-create')">
                <BreadcrumbLink :target="linkToScenarioOverview()">
                    <BrainIcon class="mr-1" />
                    {{ $t('scenarios') }}
                </BreadcrumbLink>
            </Breadcrumb>
        </template>
        <template #body>
            <div class="-mx-4 bg-gray-50">
                <div class="px-4 pb-4 pt-4">
                    <ScenarioGroupCreateForm />
                </div>
            </div>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import BrainIcon from '@/components/Icon/BrainIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import fluent from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import ScenarioGroupCreateForm from '../forms/ScenarioGroupCreateForm.vue';
import { linkToScenarioOverview } from '../scenario';

useHeadSafe({ title: () => `${fluent.$t('scenario-group-create')} - ${fluent.$t('scenarios')}` });
</script>
