<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>{{ $t('project-series-create') }}</template>
        <template #body>
            <div class="-mx-4 bg-gray-50">
                <div class="px-4 pb-4 pt-4">
                    <ProjectSeriesCreateForm />
                </div>
            </div>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import ProjectSeriesCreateForm from '../forms/series/ProjectSeriesCreateForm.vue';

useHeadSafe({ title: () => `${$t('project-series-create')} - ${$t('project-wiki')}` });
</script>
