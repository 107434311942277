<template>
    <HeaderCardsLayout v-if="scenario.id && scenario.scenarioGroup" variant="No-Logo">
        <template #headline>
            <div class="flex gap-2">
                <ScenarioIndex :index="scenario.index" :max-digits="5" :year="scenario.year" />
                <span class="text-gray-400 font-light">|</span>
                {{ scenario.nameShort }}
            </div>
        </template>
        <template #headline-extra>
            <BadgeDropDown
                :label="$t('status')"
                class="text-base font-normal"
                :current-value="getStatusBadgeCurrentValue()"
                :values="getStatusBadgeItems()"
                :status="getStatusBadgeStatus()"
                @change-value="(value: string) => onStatusBadgeChange(value)"
            />
        </template>
        <template #breadcrumb>
            <Breadcrumb :headline="$t('breadcrumb')" :current-page="scenarioCurrentPage">
                <BreadcrumbLink :target="linkToScenarioOverview()">
                    <BrainIcon class="mr-1" />
                    {{ $t('scenarios') }}
                </BreadcrumbLink>
                <BreadcrumbLink :target="linkToScenarioGroup(scenario.scenarioGroup?.id)">
                    {{
                        `${getScenarioGroupIndexString(scenario.scenarioGroup.index)} | ${
                            scenario.scenarioGroup.nameShort
                        }`
                    }}
                </BreadcrumbLink>
            </Breadcrumb>
        </template>
        <template #buttons>
            <Button
                variant="Danger-Strong"
                size="Base"
                :disabled-explanation="deleteScenarioDisabledExplanation"
                @click="onDeleteScenario()"
            >
                <template #icon>
                    <DeleteForeverIcon />
                </template>
                {{ $t('delete') }}
            </Button>
        </template>
        <template #tab-navigation>
            <TabNavigation
                :headline="$t('tab')"
                :items="tabNavigationItems"
                tab-panel-id="eventSeriesTabs"
                :tabs-disabled-text="tabsDisabledText"
                @focus-tab-panel="focusTabPanel"
            />
        </template>

        <template #body>
            <TabPanel :id="tabPanelId" ref="tabPanelRef" :headline="activeTabHeadline">
                <RouterView />
            </TabPanel>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import BadgeDropDown from '@/components/BadgeDropDown/BadgeDropDown.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import Button from '@/components/Button/Button.vue';
import BrainIcon from '@/components/Icon/BrainIcon.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import ScenarioIndex from '@/components/ScenarioIndex/ScenarioIndex.vue';
import TabNavigation from '@/components/TabNavigation/TabNavigation.vue';
import TabPanel from '@/components/TabNavigation/TabPanel.vue';
import { useFeed } from '@/feed/composables/useFeed';
import { useActiveCard } from '@/forms/composables/useActiveCard';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed, provide, ref } from 'vue';
import { useScenario } from '../composables/useScenario';
import { useScenarioStatusBadge } from '../composables/useScenarioStatusBadge';
import { useScenarioTabs } from '../composables/useScenarioTabs';
import { activeCardData, feedData, scenarioData } from '../keys';
import {
    getScenarioGroupIndexString,
    getScenarioIndexString,
    linkToScenarioGroup,
    linkToScenarioOverview,
} from '../scenario';

const props = defineProps<{ scenarioId: string }>();

const isAdmin = auth.isAdmin();
const fluent = useFluent();

const tabPanelRef = ref();
const tabPanelId = nanoid();

const scenarioId = computed(() => props.scenarioId);

const {
    activeCard,
    resetActiveCard,
    onEnterEditMode,
    onLeaveEditMode,
    showForm,
    editButtonDisabledMessage,
    setCardStatus,
    getCardStatus,
} = useActiveCard();

const { scenario, fields, errors, onInput, discardChanges, onUpdateScenario, onDeleteScenario } = useScenario(
    scenarioId,
    setCardStatus,
    resetActiveCard,
);

const { getStatusBadgeCurrentValue, getStatusBadgeItems, getStatusBadgeStatus, handleStatusBadgeChange } =
    useScenarioStatusBadge(scenario);

const changesFeedId = computed(() => scenario.value?.changes.id);
const { feedEntries, addFeedEntry, createUserTextEntry, createCreatedAtEntry, createStatusChangeEntry } =
    useFeed(changesFeedId);

const scenarioYear = computed(() => scenario.value?.year ?? 0);
const scenarioIndex = computed(() => scenario.value?.index ?? 0);

const scenarioIndexString = computed(() => getScenarioIndexString(scenarioIndex.value ?? 0, scenarioYear.value ?? 0));
const scenarioCurrentPage = computed(() => `${scenarioIndexString.value} | ${scenario.value?.nameShort}`);
const deleteScenarioDisabledExplanation = computed(() => {
    if (!isAdmin) return fluent.$t('no-permission-to-edit');
});

provide(scenarioData, { scenario, fields, errors, onInput, discardChanges, onUpdateScenario });
provide(activeCardData, {
    activeCard,
    onEnterEditMode,
    onLeaveEditMode,
    showForm,
    editButtonDisabledMessage,
    getCardStatus,
});
provide(feedData, {
    feedEntries,
    addFeedEntry,
    createUserTextEntry,
    createCreatedAtEntry,
    createStatusChangeEntry,
});

function focusTabPanel() {
    tabPanelRef.value.focus();
}

const { tabNavigationItems, tabsDisabledText, activeTabHeadline } = useScenarioTabs(activeCard);

async function onStatusBadgeChange(value: string) {
    const oldStatus = scenario.value.status;
    createStatusChangeEntry(oldStatus, value);
    handleStatusBadgeChange(value);
}

useHeadSafe({
    title: () =>
        `${scenario.value?.nameShort ?? ''} - ${scenario.value?.scenarioGroup?.nameShort ?? ''} - ${fluent.$t(
            'scenarios',
        )}`,
});
</script>
