<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>TODO Switcher</template>
        <template #icon>
            <TacticIcon class="size-12" />
        </template>

        <template #buttons>TODO Filter & Buttons</template>

        <template #body>
            <div class="bg-gray-50 h-full -mx-4 p-4">
                <div class="flex flex-row items-end gap-4">
                    <TimelineIntervalChanger
                        :current-quarter="quarter"
                        :current-year="year"
                        :minimal-year="MINIMAL_YEAR"
                        :maximal-year="MAXIMAL_YEAR"
                        :warning-no-allocations="warningNoAllocations"
                        :is-time-span-modified="isTimeSpanModified"
                        @change-quarter="onChangeQuarter"
                        @change-year="onChangeYear"
                        @click-previous-quarter="onClickPreviousQuarter"
                        @click-next-quarter="onClickNextQuarter"
                    />

                    <TimelineOptionSwitch
                        name="timeline-locations-display"
                        :active-index="activeLocationsDisplayIndex"
                        :label="$t('timeline-locations-display-label')"
                        @change-option="(index) => setActiveLocationsDisplayIndex(index)"
                    >
                        <TimelineOptionSwitchEntry
                            :icon="markRaw(GroupedEntriesIcon)"
                            :index="0"
                            :label="$t('timeline-locations-display-sections-label')"
                        />
                        <TimelineOptionSwitchEntry
                            :icon="markRaw(NestedEntriesIcon)"
                            :index="1"
                            :label="$t('timeline-locations-display-hierarchy-label')"
                        />
                    </TimelineOptionSwitch>
                </div>

                <Message v-if="timelineDataLoading" status="info" class="mt-4">
                    {{ $t('loading-timeline-data') }}
                </Message>

                <Message
                    v-if="!timelineDataLoading && timelineDataError"
                    class="mt-4"
                    status="error"
                    :headline="$t('error-fetching-timeline-data')"
                >
                    {{ timelineDataError }}
                </Message>

                <Timeline
                    v-if="timelineDataResult && site"
                    class="mt-4"
                    :site-name="site.nameShort"
                    :site-link-target="routeForLocation('Site', site.id)"
                    :start-date-time="startDateTime"
                    :end-date-time="endDateTime"
                    :previous-month-button="previousMonthButton"
                    :next-month-button="nextMonthButton"
                    :locations="locations"
                    :allocations="allocations"
                    :external-events="externalEvents"
                    :sections="showSections ? sections : undefined"
                    :allocation-variant-id="''"
                    :current-allocation-variant-label="''"
                    :has-create-buttons="false"
                    @toggle-section-expanded="toggleSectionExpanded"
                    @create-allocation="onCreateAllocation"
                    @delete-allocation="onDeleteAllocation"
                    @update-phases="onUpdatePhases"
                    @expand-to-previous-month="onExpandToPreviousMonth"
                    @expand-to-next-month="onExpandToNextMonth"
                >
                    <template #site-icon>
                        <TerrainIcon class="w-5 h-5" :title="$t('site-icon-alt')" />
                    </template>
                </Timeline>
            </div>
        </template>
    </HeaderCardsLayout>
</template>

<script setup lang="ts">
import { mapAllocationForTimeline } from '@/allocation/allocation';
import { getUnstyledAllocationVariantLabel } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import GroupedEntriesIcon from '@/components/Icon/GroupedEntriesIcon.vue';
import NestedEntriesIcon from '@/components/Icon/NestedEntriesIcon.vue';
import TacticIcon from '@/components/Icon/TacticIcon.vue';
import TerrainIcon from '@/components/Icon/TerrainIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import Message from '@/components/Message/Message.vue';
import { ExternalEvent } from '@/components/Timeline/Timeline.types';
import Timeline from '@/components/Timeline/Timeline.vue';
import { useSections } from '@/components/Timeline/composables/useSections';
import { useTimelineLocations } from '@/components/Timeline/composables/useTimelineLocations';
import { useTimelineRange } from '@/components/Timeline/composables/useTimelineRange';
import TimelineIntervalChanger from '@/components/TimelineIntervalChanger/TimelineIntervalChanger.vue';
import {
    MAXIMAL_YEAR,
    MINIMAL_YEAR,
    useTimelineIntervalChanger,
} from '@/components/TimelineIntervalChanger/composables/useTimelineIntervalChanger';
import TimelineOptionSwitch from '@/components/TimelineOptionSwitch/TimelineOptionSwitch.vue';
import TimelineOptionSwitchEntry from '@/components/TimelineOptionSwitch/TimelineOptionSwitchEntry.vue';
import { useTimelineOptionsSwitch } from '@/components/TimelineOptionSwitch/composables/useTimlineOptionSwitch';
import { getExternalEventLink } from '@/constraints/constraints';
import { getEventMainRoute } from '@/event/event';
import { routeForLocation } from '@/location/location';
import { $t } from '@/plugins/fluent';
import { getProjectLink } from '@/project/project';
import { useHeadSafe } from '@unhead/vue';
import { DateTime } from 'luxon';
import { computed, markRaw, ref } from 'vue';
import { useDesignerTimelineData } from './composables/useDesignerTimelineData';

const currentScenario = ref('Ungespeichert');

useHeadSafe({ title: () => `${currentScenario.value} - ${$t('allocation-designer')}` });

// TODO Persist view options in URL

const timelineStart = computed(() => DateTime.now());

const {
    startDateTime,
    endDateTime,
    isTimeSpanModified,
    quarter,
    year,
    previousMonthButton,
    nextMonthButton,
    onChangeQuarter,
    onChangeYear,
    onClickPreviousQuarter,
    onClickNextQuarter,
    onExpandToPreviousMonth,
    onExpandToNextMonth,
} = useTimelineRange(timelineStart);

const {
    loading: timelineDataLoading,
    error: timelineDataError,
    result: timelineDataResult,
    site,
    rooms,
    areas,
} = useDesignerTimelineData(startDateTime, endDateTime);

const { activeLocationsDisplayIndex, showSections, setActiveLocationsDisplayIndex } = useTimelineOptionsSwitch();

const { getLocations } = useTimelineLocations(site, rooms, areas, showSections);
const locations = computed(() => getLocations().value);

const { sections, toggleSectionExpanded } = useSections();

const allocations = computed(() => {
    const result = timelineDataResult.value;
    const eventAllocations =
        result?.allocationRooms?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.event.nameShort;
            const linkTarget = getEventMainRoute(variant.event.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                $t('allocation-bar-label', { groupLabel: label, variantLabel }),
                variantLabel,
                label,
                'Event',
                variant.id,
                linkTarget,
            );
        }) ?? [];
    const eventAreaAllocatons =
        result?.allocationAreas?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.event.nameShort;
            const linkTarget = getEventMainRoute(variant.event.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                $t('allocation-bar-label', { groupLabel: label, variantLabel }),
                variantLabel,
                label,
                'Event',
                variant.id,
                linkTarget,
            );
        }) ?? [];
    const projectAllocations =
        result?.projectAllocationRooms?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.project.nameShort;
            const linkTarget = getProjectLink(variant.project.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                $t('allocation-bar-label', { groupLabel: label, variantLabel }),
                variantLabel,
                label,
                'Project',
                variant.id,
                linkTarget,
            );
        }) ?? [];
    const projectAreaAllocations =
        result?.projectAllocationAreas?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.project.nameShort;
            const linkTarget = getProjectLink(variant.project.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                $t('allocation-bar-label', { groupLabel: label, variantLabel }),
                variantLabel,
                label,
                'Project',
                variant.id,
                linkTarget,
            );
        }) ?? [];
    return eventAllocations.concat(projectAllocations).concat(eventAreaAllocatons).concat(projectAreaAllocations);
});

const allocationsCount = computed(() => allocations.value.length);

const { warningNoAllocations } = useTimelineIntervalChanger(timelineDataLoading, allocationsCount);

const externalEvents = computed(() => {
    return (
        timelineDataResult.value?.calendarEvents?.nodes.map((externalEvent) => {
            const startDateTime = DateTime.fromISO(externalEvent.start).startOf('day');
            // TODO Test if the duration is correctly calculated for edge cases --> Once https://koelnmesse.atlassian.net/browse/SFE-919 is implemented, we can use the same logic everywhere
            const endDateTime = DateTime.fromISO(externalEvent.end).startOf('day');
            const durationInDays = endDateTime.diff(startDateTime, 'days').days + 1;

            return {
                startDateTime,
                cellSpan: durationInDays,
                label: externalEvent.name,
                variantLabel: undefined,
                groupLabel: externalEvent.name,
                id: externalEvent.id,
                isEditable: false,
                linkTarget: getExternalEventLink(externalEvent.id), // TODO Might be necessary to adapt once we've reimplemented the external events
            } satisfies ExternalEvent;
        }) ?? []
    );
});

function onCreateAllocation() {
    // TODO Implement once there is a concept how (and if) this should work here
}

function onDeleteAllocation() {
    // TODO Implement once there is a concept how (and if) this should work here
}

function onUpdatePhases() {
    // TODO Implement once there is a concept how (and if) this should work here
}
</script>
