import { CommentChangesStatus } from '@/components/Feed/types';
import {
    CreateCreatedAtEntryDocument,
    CreateStatusChangeEntryDocument,
    CreateUserTextEntryDocument,
    DeleteFeedEntryDocument,
    GetFeedDocument,
    UpdateUserTextEntryDocument,
} from '@/generated/graphql';
import { useMyUser } from '@/user/composables/useMyUser';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { DateTime } from 'luxon';
import { computed, ref, Ref, toValue } from 'vue';
import { EditableFeedEntry } from '../types';

export function useFeed(feedId: Ref<string>) {
    const { currentUser } = useMyUser();
    const feedEntries: Ref<EditableFeedEntry[]> = ref([]);

    const getFeed = useQuery(
        GetFeedDocument,
        computed(() => ({ id: toValue(feedId) })),
        { enabled: !!feedId },
    );

    getFeed.onResult((result) => {
        if (!result.loading && result.data && result.data.feed?.feedEntries.nodes) {
            feedEntries.value = result.data.feed.feedEntries.nodes.map((n) => ({ ...n, hasUnsavedChanges: 'None' }));
        }
    });

    function addFeedEntry(feedEntry: EditableFeedEntry) {
        feedEntries.value = [feedEntry, ...feedEntries.value];
    }

    const createUserTextEntryMutation = useMutation(CreateUserTextEntryDocument);

    async function createUserTextEntry(text: string, hasUnsavedChanges?: CommentChangesStatus) {
        const response = await createUserTextEntryMutation.mutate({
            feedId: toValue(feedId),
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
            type: 'user-text',
            text,
        });
        if (response?.errors) {
            throw new Error(`Could not create Feed Entry: ${response?.errors}`);
        }
        if (!response?.data?.createFeedEntry?.feedEntry) {
            throw new Error('Create feed entry did not return data.');
        }
        const unsavedChanges = hasUnsavedChanges ?? ('None' as const);
        const entry = { ...response.data.createFeedEntry.feedEntry, hasUnsavedChanges: unsavedChanges };
        addFeedEntry(entry);
    }

    const createCreatedAtEntryMutation = useMutation(CreateCreatedAtEntryDocument);

    async function createCreatedAtEntry(createdAt: string) {
        const response = await createCreatedAtEntryMutation.mutate({
            feedId: toValue(feedId),
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
            type: 'created-at',
            objectCreatedAt: createdAt,
        });
        if (response?.errors) {
            throw new Error(`Could not create Feed Entry: ${response?.errors}`);
        }
        if (!response?.data?.createFeedEntry?.feedEntry) {
            throw new Error('Create feed entry did not return data.');
        }
        const entry = { ...response.data.createFeedEntry.feedEntry, hasUnsavedChanges: 'None' as const };
        addFeedEntry(entry);
    }

    const createStatusChangeEntryMutation = useMutation(CreateStatusChangeEntryDocument);

    async function createStatusChangeEntry(oldStatus: string, newStatus: string) {
        const response = await createStatusChangeEntryMutation.mutate({
            feedId: toValue(feedId),
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
            type: 'new-status',
            oldStatus,
            newStatus,
        });
        if (response?.errors) {
            throw new Error(`Could not create Feed Entry: ${response.errors}`);
        }
        if (!response?.data?.createFeedEntry?.feedEntry) {
            throw new Error('Create feed entry did not return data.');
        }
        const entry = { ...response.data.createFeedEntry.feedEntry, hasUnsavedChanges: 'None' as const };
        addFeedEntry(entry);
    }

    function setUnsavedChanges(entryId: string, status: CommentChangesStatus) {
        feedEntries.value = feedEntries.value.map((entry) =>
            entry.id === entryId
                ? {
                      ...entry,
                      hasUnsavedChanges: status,
                  }
                : entry,
        );
    }

    function setText(entryId: string, text: string) {
        feedEntries.value = feedEntries.value.map((entry) =>
            entry.id === entryId
                ? {
                      ...entry,
                      text,
                      hasUnsavedChanges: entry.hasUnsavedChanges === 'New' ? 'New' : 'Edited',
                  }
                : entry,
        );
    }

    function createUnsavedFeedEntry(text: string) {
        const { id, icon, name } = toValue(currentUser);
        const entry = {
            id: '',
            createdAt: DateTime.now().toISO(),
            createdById: id,
            createdByName: name,
            createdBy: {
                id,
                icon,
            },
            type: 'user-text',
            text,
            markdownText: '',
            oldStatus: '',
            newStatus: '',
            hasUnsavedChanges: 'New' as const,
        };
        addFeedEntry(entry);
    }

    const updateUserTextEntry = useMutation(UpdateUserTextEntryDocument);
    const deleteFeedEntry = useMutation(DeleteFeedEntryDocument);

    function resetUnsavedChanges() {
        feedEntries.value = feedEntries.value
            .filter((entry) => entry.hasUnsavedChanges !== 'New')
            .map((entry) => ({ ...entry, hasUnsavedChanges: 'None' }));
    }

    const hasUnsavedChanges = computed(
        () => feedEntries.value.filter((entry) => entry.hasUnsavedChanges !== 'None').length > 0,
    );

    async function onSaveFeed() {
        const entriesToCreate = feedEntries.value.filter((entry) => entry.hasUnsavedChanges === 'New');
        const entriesToUpdate = feedEntries.value.filter((entry) => entry.hasUnsavedChanges === 'Edited');
        const entriesToDelete = feedEntries.value.filter((entry) => entry.hasUnsavedChanges === 'Deleted');

        // where does createdById comes from?
        for (const entry of entriesToCreate) {
            await createUserTextEntryMutation.mutate({
                feedId: toValue(feedId),
                createdById: toValue(currentUser).id,
                ...entry,
            });
        }

        for (const entry of entriesToUpdate) {
            await updateUserTextEntry.mutate({
                ...entry,
                updatedById: toValue(currentUser).id,
                updatedByName: toValue(currentUser).name,
            });
        }

        for (const entry of entriesToDelete) {
            await deleteFeedEntry.mutate({ id: entry.id });
        }

        feedEntries.value = feedEntries.value.map((entry) => ({ ...entry, hasUnsavedChanges: 'None' }));
    }

    function onDiscardFeedChanges() {
        resetUnsavedChanges();
    }

    return {
        feedEntries,
        hasUnsavedChanges,
        addFeedEntry,
        createUserTextEntry,
        createCreatedAtEntry,
        createStatusChangeEntry,
        setUnsavedChanges,
        setText,
        createUnsavedFeedEntry,
        onSaveFeed,
        onDiscardFeedChanges,
    };
}
