import auth from '@/auth/auth';
import { EditableCardStatus } from '@/components/Card/types';
import { useFluent } from 'fluent-vue';
import { ref } from 'vue';

export const EDIT_MODES = ['Edit-Untouched', 'Edit-Touched'];

export function useActiveCard() {
    const fluent = useFluent();
    const isAdmin = auth.isAdmin();

    const activeCard = ref({ id: '', label: '', status: 'View' as EditableCardStatus });

    function updateActiveCard(id: string, label: string, status: EditableCardStatus) {
        activeCard.value = { id, label, status };
    }

    function resetActiveCard() {
        activeCard.value = { id: '', label: '', status: 'View' };
    }

    function setCardStatus(status: EditableCardStatus) {
        activeCard.value = { ...activeCard.value, status };
    }

    function getCardStatus(id: string): EditableCardStatus {
        return activeCard.value.id === id ? activeCard.value.status : 'View';
    }

    function editButtonDisabledMessage(id: string) {
        if (!isAdmin) return fluent.$t('no-permission-to-edit');
        if (activeCard.value.id !== '' && activeCard.value.id !== id) return fluent.$t('other-card-is-editing');
        return '';
    }

    function onEnterEditMode(id: string, label: string) {
        activeCard.value = { id, label, status: 'Edit-Untouched' };
    }

    function onLeaveEditMode() {
        activeCard.value = { id: '', label: '', status: 'View' };
    }

    function showForm(id: string) {
        if (activeCard.value.id === id && EDIT_MODES.includes(activeCard.value.status)) {
            return true;
        }
        return false;
    }

    return {
        activeCard,
        updateActiveCard,
        resetActiveCard,
        setCardStatus,
        getCardStatus,
        onEnterEditMode,
        onLeaveEditMode,
        showForm,
        editButtonDisabledMessage,
    };
}
