<template>
    <div class="py-2 px-4 items-start flex flex-col">
        <p class="text-lg text-gray-900 font-bold hyphens-auto">
            <div class="flex gap-1">
                <slot name="additional-content"></slot>
                <TextLink :to="props.linkTarget">
                    {{ props.linkText }}
                </TextLink>
            </div>
        </p>
        <p class="text-sm text-gray-500 font-light">
            <slot name="subtitle" ></slot>
        </p>
    </div>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router';
import TextLink from '../TextLink/TextLink.vue';

type CardRowLinkWithSubtitleProps = {
    linkText: string;
    linkTarget: RouteLocationRaw;
};

const props = defineProps<CardRowLinkWithSubtitleProps>();
</script>
