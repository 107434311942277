import { ActiveCard } from '@/components/Card/types';
import { isTabDisabled } from '@/project/project';
import { useFluent } from 'fluent-vue';
import { computed, Ref, toValue } from 'vue';
import { useRoute } from 'vue-router';

export function useScenarioGroupTabs(activeCard: Ref<ActiveCard>) {
    const fluent = useFluent();
    const route = useRoute();

    const tabs = [
        { route: 'scenario-group-scenarios-tab', label: fluent.$t('scenarios') },
        { route: 'scenario-group-masterdata-tab', label: fluent.$t('master-data') },
        { route: 'scenario-group-organisation-tab', label: fluent.$t('organisational-structure') },
        { route: 'scenario-group-changes-tab', label: fluent.$t('changes') },
    ];

    const tabNavigationItems = computed(() => {
        return tabs.map((tab) => ({
            target: { name: tab.route },
            routeName: tab.route,
            label: tab.label,
            isDisabled: isTabDisabled(tab.route, activeCard),
        }));
    });

    const tabsDisabledText = computed(() => fluent.$t('tabs-disabled', { name: toValue(activeCard).label }));

    const activeTabHeadline = computed(() => {
        return fluent.$t('tab-panel', {
            activeTabName: tabNavigationItems.value.find((item) => item.routeName === route.name)?.label ?? '',
        });
    });

    return {
        tabNavigationItems,
        tabsDisabledText,
        activeTabHeadline,
    };
}
