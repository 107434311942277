export const scenarioGroupStates = ['active', 'passive', 'realized', 'discontinued'] as const;
export type ScenarioGroupStatus = (typeof scenarioGroupStates)[number];

export const scenarioStates = ['active-option', 'active-idea', 'active-fix', 'passive', 'discontinued'];
export type ScenarioStatus = (typeof scenarioStates)[number];

export type ScenarioGroupFields = {
    [key: string]: string;
    nameShort: string;
    nameLong: string;
    intention: string;
    description: string;
    kapmanNumber: string;
    companyGroup: string;
    businessDomain: string;
    department: string;
    responsible: string;
};

export type ScenarioFields = {
    [Key: string]: string | number;
    nameShort: string;
    nameLong: string;
    year: number;
    intention: string;
    description: string;
};

export type ScenarioGroupErrors = {
    nameShort: string[];
    nameLong: string[];
    intention: string[];
    description: string[];
    kapmanNumber: string[];
    companyGroup: string[];
    businessDomain: string[];
    department: string[];
    responsible: string[];
};

export type ScenarioErrors = {
    nameShort: string[];
    nameLong: string[];
    year: string[];
    intention: string[];
    description: string[];
};
