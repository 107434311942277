import auth from '@/auth/auth';
import { BadgeDropDownStatus, BadgeDropDownValue } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import { ScenarioDetailsFragment, UpdateScenarioStatusDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, Ref, toValue } from 'vue';
import { getScenarioBadgeVariant } from '../scenario';
import { ScenarioGroupStatus, scenarioStates } from '../types';

export function useScenarioStatusBadge(scenario: Ref<ScenarioDetailsFragment>) {
    const fluent = useFluent();
    const isAdmin = auth.isAdmin();

    const status = computed(() => toValue(scenario).status);
    const updateScenarioStatus = useMutation(UpdateScenarioStatusDocument);

    const statusBadgeDisabledExplanation = computed(() => {
        return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
    });

    function getStatusBadgeCurrentValue(): BadgeDropDownValue & { tooltip?: string } {
        return {
            id: toValue(status),
            label: fluent.$t(`scenario-status-${toValue(status)}`),
            variant: getScenarioBadgeVariant(toValue(status) as ScenarioGroupStatus),
            tooltip: undefined,
        };
    }

    function getStatusBadgeItems(): Array<BadgeDropDownValue & { isSelected: boolean }> {
        return scenarioStates.map((s) => ({
            id: s,
            label: $t(`scenario-status-${s}`),
            variant: getScenarioBadgeVariant(s),
            isSelected: toValue(status) === s,
        }));
    }

    function getStatusBadgeStatus(): BadgeDropDownStatus {
        if (statusBadgeDisabledExplanation.value) return 'Disabled';
        if (updateScenarioStatus.loading.value) return 'Loading';
        if (updateScenarioStatus.error.value) return 'Retry';
        return 'Default';
    }

    async function handleStatusBadgeChange(newStatus: string) {
        const response = await updateScenarioStatus.mutate({
            scenarioId: toValue(scenario).id,
            status: newStatus,
        });
        if (response?.errors) {
            throw new Error(`Could not update ScenarioGroup status: ${response.errors}`);
        }
        if (!response?.data?.updateScenario?.scenario) {
            throw new Error('Update ScenarioGroup status did not return data.');
        }
        return response.data.updateScenario.scenario.status;
    }

    return {
        statusBadgeDisabledExplanation,
        getStatusBadgeCurrentValue,
        getStatusBadgeItems,
        getStatusBadgeStatus,
        handleStatusBadgeChange,
    };
}
