<template>
    <div v-if="data && scenarioGroup" class="space-y-4">
        <div v-if="scenarios.length === 0">
            <EmptyState>
                <template #description>
                    {{ $t('no-scenarios') }}
                </template>
                <template #call-to-action>
                    <Button
                        size="Base"
                        variant="Info-Strong"
                        @click="goToCreateScenario(scenarioGroup.id, scenarioGroup.nameShort, scenarioGroup.index)"
                    >
                        <template #icon>
                            <PlusIcon class="size-5" />
                        </template>
                        {{ $t('scenario-create') }}
                    </Button>
                </template>
            </EmptyState>
        </div>
        <div v-else>
            <div class="space-y-2">
                <CollapsibleHeadline
                    v-for="(yearScenarios, year) in scenarioYears"
                    :key="year"
                    :initial-is-open="isHeadlineOpen(year)"
                >
                    <template #headline>{{ year }}</template>
                    <template #content>
                        <div class="space-y-4">
                            <HeadlessCard
                                v-for="scenario in yearScenarios"
                                :key="scenario.id"
                                :invisible-headline="scenario.nameShort"
                            >
                                <div class="flex justify-between gap-2 py-2 px-4">
                                    <div class="flex gap-2">
                                        <ScenarioIndex :index="scenario.index" :max-digits="5" :year="scenario.year" />
                                        <span class="text-gray-400 font-light">|</span>
                                        <TextLink :to="linkToScenario(scenario.id)" class="font-bold">
                                            {{ scenario.nameShort }}
                                        </TextLink>
                                    </div>
                                    <Badge size="Extra-Small" :variant="getScenarioBadgeVariant(scenario.status)">
                                        {{ $t(`scenario-status-${scenario.status}`) }}
                                    </Badge>
                                </div>
                            </HeadlessCard>
                        </div>
                    </template>
                </CollapsibleHeadline>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import Badge from '@/components/Badge/Badge.vue';
import Button from '@/components/Button/Button.vue';
import HeadlessCard from '@/components/Card/HeadlessCard.vue';
import CollapsibleHeadline from '@/components/CollapsibleHeadline/CollapsibleHeadline.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import PlusIcon from '@/components/Icon/PlusIcon.vue';
import ScenarioIndex from '@/components/ScenarioIndex/ScenarioIndex.vue';
import TextLink from '@/components/TextLink/TextLink.vue';
import { scenarioGroupData } from '@/scenario/keys';
import { getScenarioBadgeVariant, goToCreateScenario, linkToScenario } from '@/scenario/scenario';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { computed, inject } from 'vue';

const data = inject(scenarioGroupData);
const fluent = useFluent();

const scenarioGroup = computed(() => data?.scenarioGroup.value);
const scenarios = computed(() => scenarioGroup.value?.scenarios.nodes ?? []);
const scenarioYears = computed(() => Object.groupBy(scenarios.value, ({ year }) => year.toString()));
const thisYear = DateTime.now().year.toString();
const openYear = computed(() => {
    const keys = Object.keys(scenarioYears.value);
    if (keys.length === 1) {
        return keys[0];
    }
    if (keys.includes(thisYear)) {
        return thisYear;
    }
    return keys[0];
});

function isHeadlineOpen(year: string) {
    return year === openYear.value;
}

useHeadSafe({
    title: () => `${fluent.$t('scenarios')} - ${scenarioGroup?.value?.nameShort} - ${fluent.$t('scenario-group')}`,
});
</script>
