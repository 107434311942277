<template>
    <div v-if="series" class="space-y-4">
        <FlexibleCard headline-id="cycle" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('cycle')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('cycle')"
                    @enter-edit-mode="cardFunctions?.updateCard('cycle', $t('cycle'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectSeriesCycleSchema)"
                    @save="dataFunctions?.save(ProjectSeriesCycleSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('cycle') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectSeriesCycleForm v-if="showForm('cycle')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('cycle')"
                            :text="valueToString(series.cycle)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-cycle')"
                            :text="valueToString(series.notesCycle)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="appointment" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('appointment')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('appointment')"
                    @enter-edit-mode="cardFunctions?.updateCard('appointment', $t('appointment'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectSeriesAppointmentSchema)"
                    @save="dataFunctions?.save(ProjectSeriesAppointmentSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('appointment') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectSeriesAppointmentForm v-if="showForm('appointment')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('project-notes-dayorder')"
                            :text="valueToString(series.notesDayOrder)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('project-notes-appointment')"
                            :text="valueToString(series.notesAppointment)"
                            :has-border-bottom="true"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="location" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('location')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('location')"
                    @enter-edit-mode="cardFunctions?.updateCard('location', $t('location'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectSeriesLocationSchema)"
                    @save="dataFunctions?.save(ProjectSeriesLocationSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('location') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectSeriesLocationForm v-if="showForm('location')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('location-requirements')"
                            :text="valueToString(series.locationRequirements)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('allocation-hints')"
                            :text="valueToString(series.allocationHints)"
                            :has-border-bottom="true"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { valueToString } from '@/forms/forms';
import { $t } from '@/plugins/fluent';
import ProjectSeriesAppointmentForm from '@/project/forms/series/ProjectSeriesAppointmentForm.vue';
import ProjectSeriesCycleForm from '@/project/forms/series/ProjectSeriesCycleForm.vue';
import ProjectSeriesLocationForm from '@/project/forms/series/ProjectSeriesLocationForm.vue';
import { activeCardData, activeCardFunctions, formFunctions, projectSeriesData } from '@/project/keys';
import { useHeadSafe } from '@unhead/vue';
import { inject, ref } from 'vue';
import { ProjectSeriesAppointmentSchema, ProjectSeriesCycleSchema, ProjectSeriesLocationSchema } from '../../schemas';

const data = inject(projectSeriesData);
const series = data?.series;

useHeadSafe({ title: () => `${$t('appointment-and-location')} - ${series?.value.nameShort} - ${$t('project-wiki')}` });

const dataFunctions = inject(formFunctions);
const cardFunctions = inject(activeCardFunctions);
const activeCard = inject(activeCardData);
const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard ?? ref(emptyActiveCard()));
</script>
