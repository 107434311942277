<template>
    <div class="flex gap-1">
        <label :for="inputId" class="block text-sm font-medium text-gray-700 text-nowrap">
            {{ label }}

            <Tooltip>
                <template #trigger="tooltipProps">
                    <span
                        v-if="props.isRequired"
                        class="text-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 rounded-[0.0625rem]"
                        :aria-labelledby="tooltipProps['aria-labelledby']"
                        :tabindex="0"
                    >
                        *
                    </span>
                </template>

                <template #text> {{ $t('required-field') }} </template>
            </Tooltip>
        </label>
        <HelpButton v-if="helpButtonText" :text="helpButtonText" />
    </div>
</template>

<script setup lang="ts">
import HelpButton from '../HelpButton/HelpButton.vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type LabelProps = {
    label: string;
    inputId: string;
    helpButtonText?: string;
    isRequired?: boolean;
};

const props = defineProps<LabelProps>();
</script>
