<template>
    <Tooltip>
        <template #trigger="tooltipProps">
            <button
                class="rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-gray-700"
                type="button"
                v-bind="tooltipProps"
            >
                <InfoIcon
                    class="text-gray-500 w-5 h-5"
                    :class="{
                        'text-gray-500': !props.status || props.status === 'Info',
                        'text-red-700': props.status === 'Error',
                    }"
                />
            </button>
        </template>
        <template #text>
            <div class="w-max max-w-[15rem]">{{ text }}</div>
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import InfoIcon from '../Icon/InfoIcon.vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type HelpButtonProps = {
    text: string;
    status?: 'Info' | 'Error';
};

const props = defineProps<HelpButtonProps>();
</script>
