<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>{{ $t('scenario-create') }}</template>
        <template #breadcrumb>
            <Breadcrumb :headline="$t('breadcrumb')" :current-page="$t('scenario-create')">
                <BreadcrumbLink :target="linkToScenarioOverview()">
                    <BrainIcon class="mr-1" />
                    {{ $t('scenarios') }}
                </BreadcrumbLink>
                <BreadcrumbLink :target="linkToScenarioGroup(scenarioGroupId)">
                    {{ scenarioGroupNameWithIndex }}
                </BreadcrumbLink>
            </Breadcrumb>
        </template>
        <template #body>
            <div class="-mx-4 bg-gray-50">
                <div class="px-4 pb-4 pt-4">
                    <ScenarioCreateForm :scenario-group-id="scenarioGroupId" />
                </div>
            </div>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import BrainIcon from '@/components/Icon/BrainIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { computed } from 'vue';
import ScenarioCreateForm from '../forms/ScenarioCreateForm.vue';
import { getScenarioGroupIndexString, linkToScenarioGroup, linkToScenarioOverview } from '../scenario';

const props = defineProps<{ scenarioGroupId: string; scenarioGroupName: string; scenarioGroupIndex: number }>();

const scenarioGroupIndex = getScenarioGroupIndexString(props.scenarioGroupIndex);
const scenarioGroupNameWithIndex = computed(() => `${scenarioGroupIndex} | ${props.scenarioGroupName}`);

useHeadSafe({ title: () => `${$t('scenario-create')} - ${$t('scenarios')}` });
</script>
