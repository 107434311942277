import { GetAllocationUsageIdByNameDocument, IntervalInput } from '@/generated/graphql';
import apolloClient from '@/plugins/apollo';
import { DateTime, Duration } from 'luxon';
import {
    Allocation,
    AllocationAggregationForm,
    AllocationPhase,
    AllocationPhaseType,
    EnrichedAllocation,
    EnrichedAllocationPhase,
    PhaseType,
    PhasesPerDay,
} from '../Timeline.types';
import { TimelineDayAllocation, TimelineSection, VisibleAllocationStart } from '../TimelineView.types';

export function enrichAllocations(allocations: Allocation[]): EnrichedAllocation[] {
    return allocations.map((allocation) => {
        const phasesPerDay = calculateIsPhaseEndPerDay(allocation.phases);
        const phases = calculateStartDateTimesForPhases(allocation.phases, allocation.startDateTime);

        return {
            ...allocation,
            phases,
            phasesPerDay,
        } satisfies EnrichedAllocation;
    });
}

export function calculateAggregatedAllocationForm(
    isPreviousDayAggregation: boolean,
    allocations: TimelineDayAllocation[],
    isNextDayAggregation: boolean,
): AllocationAggregationForm {
    if (allocations.length > 0) {
        if (!isPreviousDayAggregation && !isNextDayAggregation) {
            return 'allocation-single';
        } else if (!isPreviousDayAggregation && isNextDayAggregation) {
            return 'allocation-start';
        } else if (isPreviousDayAggregation && !isNextDayAggregation) {
            return 'allocation-end';
        } else if (isPreviousDayAggregation && isNextDayAggregation) {
            return 'allocation-middle';
        }
    }

    return 'no-allocation';
}

export function calculateAllocationStartsPerDay(
    allocations: EnrichedAllocation[],
    dayDateTime: DateTime,
    timelineStartDateTime: DateTime,
    timelineEndDateTime: DateTime,
): VisibleAllocationStart[] {
    const isFirstDayInTimelineRange = dayDateTime.diff(timelineStartDateTime, 'days').days === 0;

    const allocationsPerDay = allocations.map((allocation: EnrichedAllocation): VisibleAllocationStart | null => {
        const startsBeforeOrOnFirstDay = allocation.startDateTime.diff(timelineStartDateTime, 'days').days < 0;
        const startsOnThisDay = allocation.startDateTime.diff(dayDateTime, 'days').days === 0;
        const daysOccupied = countDaysUsedByAllocation(allocation, timelineStartDateTime, timelineEndDateTime);

        if ((isFirstDayInTimelineRange && startsBeforeOrOnFirstDay) || startsOnThisDay) {
            return {
                type: 'Visible-Allocation-Start',
                visibleCellSpan: daysOccupied,
                id: allocation.id,
                allocationType: allocation.allocationType,
                allocationVariantId: allocation.allocationVariantId,
                allPhases: allocation.phases,
                label: allocation.label,
                variantLabel: allocation.variantLabel,
                groupLabel: allocation.groupLabel,
                isEditable: allocation.isEditable,
                linkTarget: allocation.linkTarget,
                startDateTime: allocation.startDateTime,
                endDateTime: allocation.endDateTime,
                variant: allocation.variant,
            } satisfies VisibleAllocationStart;
        }

        return null;
    });

    return allocationsPerDay.filter(
        (allocationsDay): allocationsDay is VisibleAllocationStart => allocationsDay !== null,
    );
}

export function mapToPhaseTypes(usageName: string): AllocationPhaseType {
    const executionPhases = [
        'Veranstaltung',
        'Parken',
        'Gastro',
        'Lager',
        'Mobiler Eingang',
        'Kongress',
        'Logistik',
        'Service-/Sammelplatz',
        'Freigelände',
        'Baumaßnahme',
        'Wartung/Instandhaltung',
        'Sperrfläche',
        'Optional',
    ];

    if (executionPhases.includes(usageName)) {
        return 'Durchführung';
    }

    // TODO These should not be repeated here, instead we should derive the types from these values
    const allocationPhases = [
        'Rüstzeit',
        'Vorgezogener interner Aufbau',
        'Vorgezogener externer Aufbau',
        'Regulärer Aufbau',
        'Durchführung',
        'Regulärer Abbau',
        'Verlängerter externer Abbau',
        'Verlängerter interner Abbau',
        'Technischer Abbau',
    ];

    if (allocationPhases.includes(usageName)) {
        return usageName as AllocationPhaseType;
    }

    throw new Error(`Unknown usage name/phase type: ${usageName}`);
}

export async function mapTypeToUsageId(phaseType: PhaseType): Promise<string> {
    const result = await apolloClient.query({
        query: GetAllocationUsageIdByNameDocument,
        variables: { name: phaseType },
    });
    if (result.data.allocationUsageByName?.id) return result.data.allocationUsageByName?.id;
    throw new Error(`Unknown name/phase type: ${phaseType}`);
}

function calculateStartDateTimesForPhases(
    phases: AllocationPhase[],
    allocationStartDateTime: DateTime,
): EnrichedAllocationPhase[] {
    let cellSpanCount = 0;

    return phases.map((phase) => {
        const enrichedPhase = {
            ...phase,
            startDateTime: allocationStartDateTime.plus({ days: cellSpanCount }),
        } satisfies EnrichedAllocationPhase;

        cellSpanCount += phase.cellSpan;

        return enrichedPhase;
    });
}

function calculateIsPhaseEndPerDay(phases: AllocationPhase[]): PhasesPerDay[] {
    return phases.reduce<PhasesPerDay[]>((phases, phase) => {
        if (phase.cellSpan <= 0) {
            throw new Error(`Unexpected cell span: ${phase.cellSpan}`);
        }

        if (!Number.isInteger(phase.cellSpan)) {
            throw new Error(`Expected cell span to be an integer: ${phase.cellSpan}`);
        }

        return [
            ...phases,
            ...Array(phase.cellSpan - 1).fill({
                ...phase,
                isPhaseEnd: false,
            }),
            { ...phase, isPhaseEnd: true },
        ];
    }, []);
}

export function validateAllocationDates(allocations: EnrichedAllocation[]): void {
    allocations.forEach((allocation) => {
        const totalCellSpan = allocation.phases.reduce((sum, phase) => {
            return sum + phase.cellSpan;
        }, 0);

        if (allocation.endDateTime.diff(allocation.startDateTime, 'days').days !== totalCellSpan) {
            throw new Error(
                `Total cell span "${totalCellSpan}" in allocation doesn't match with end date time "${allocation.endDateTime.toLocaleString()}" (expected "${allocation.startDateTime
                    .plus({ days: totalCellSpan })
                    .toLocaleString()}"). Allocation ID: ${allocation.id}.`,
            );
        }

        if (allocation.phasesPerDay.length === 0) {
            throw new Error(`Allocation ${allocation.id} has no phases`);
        }

        const calculatedEndDateTime = allocation.startDateTime.plus({ days: allocation.phasesPerDay.length });

        if (!calculatedEndDateTime.hasSame(allocation.endDateTime, 'day')) {
            const startIso = allocation.startDateTime.toISO();
            const daysCount = allocation.phasesPerDay.length;
            const calculatedEndIso = calculatedEndDateTime.toISO();
            const databaseEndIso = allocation.endDateTime.toISO();

            throw new Error(
                `Expected start date: '${startIso}' + '${daysCount}' days to be end date: '${calculatedEndIso}', got: ${databaseEndIso} (allocation ID: ${allocation.id}, location ID: ${allocation.locationId})`,
            );
        }
    });
}

export function validateAllocationsAreUnique(allocations: Allocation[]): void {
    const countedAllocationIds = allocations
        .map((allocation) => ({
            id: allocation.id,
            count: 1,
        }))
        .reduce<Record<string, number>>((result, allocation) => {
            result[allocation.id] = (result[allocation.id] ?? 0) + allocation.count;

            return result;
        }, {});

    const duplicates = Object.keys(countedAllocationIds).filter((allocation) => countedAllocationIds[allocation] > 1);

    if (duplicates.length > 0) {
        throw new Error(`Found allocation ID duplicates: ${duplicates.join(', ')}`);
    }
}

export function countAllocations(sections: TimelineSection[]): number {
    let maxValue = 0;

    sections.forEach((section) => {
        section.rows.forEach((row) => {
            row.days.forEach((day) => {
                if (day.allocationStarts.length > maxValue) {
                    maxValue = day.allocationStarts.length;
                }
            });
        });
    });

    return maxValue;
}

export function getDurationInDays(interval: IntervalInput) {
    const durationInDays = Duration.fromObject({
        seconds: interval.seconds ?? undefined,
        minutes: interval.minutes ?? undefined,
        hours: interval.hours ?? undefined,
        days: interval.days ?? undefined,
        months: interval.months ?? undefined,
        years: interval.years ?? undefined,
    }).as('days');

    return durationInDays;
}

export function countDaysUsedByAllocation(
    allocation: EnrichedAllocation,
    timelineStartDateTime: DateTime,
    timelineEndDateTime: DateTime,
): number {
    const startDateTime =
        allocation.startDateTime < timelineStartDateTime ? timelineStartDateTime : allocation.startDateTime;
    const endDateTime = allocation.endDateTime > timelineEndDateTime ? timelineEndDateTime : allocation.endDateTime;

    return endDateTime.diff(startDateTime, 'days').days;
}
