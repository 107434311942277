<template>
    <FlexibleCard
        v-if="scenarioGroup && activeCardInject && scenarioGroupInject"
        :headline-id="cardId"
        expandable-status="Not-Collapsible"
    >
        <template #header="{ headlineId }">
            <CardHeaderEditable
                :status="activeCardInject.getCardStatus(cardId)"
                :edit-button-disabled-explanation="activeCardInject.editButtonDisabledMessage(cardId)"
                @enter-edit-mode="activeCardInject.onEnterEditMode(cardId, cardLabel)"
                @leave-edit-mode="activeCardInject.onLeaveEditMode"
                @discard-changes="onDiscardChanges"
                @save="onSave"
            >
                <CardHeadline :headline-id="headlineId">
                    {{ cardLabel }}
                </CardHeadline>
            </CardHeaderEditable>
        </template>
        <template #body>
            <ContentFormTransition>
                <ScenarioGroupMasterdataForm
                    v-if="activeCardInject.showForm(cardId) && fields && errors"
                    :fields="fields"
                    :errors="errors"
                    class="p-5"
                    @on-input="scenarioGroupInject.onInput($event)"
                />
                <div v-else>
                    <CardRowText
                        :label="$t('name-short')"
                        :text="valueToString(scenarioGroup.nameShort)"
                        :has-border-bottom="false"
                    ></CardRowText>
                    <CardRowText
                        :label="$t('name-long')"
                        :text="valueToString(scenarioGroup.nameLong)"
                        :has-border-bottom="false"
                    />
                    <CardRowText
                        :label="$t('intention')"
                        :text="valueToString(scenarioGroup.intention)"
                        :has-border-bottom="false"
                    />
                    <CardRowText
                        :label="$t('description')"
                        :text="valueToString(scenarioGroup.description)"
                        :has-border-bottom="false"
                    />
                    <CardRowText
                        :label="$t('recent-number')"
                        :text="valueToString(scenarioGroup.kapmanNumber)"
                        :has-border-bottom="false"
                    />
                </div>
            </ContentFormTransition>
        </template>
    </FlexibleCard>
</template>
<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { valueToString } from '@/forms/forms';
import fluent from '@/plugins/fluent';
import ScenarioGroupMasterdataForm from '@/scenario/forms/scenariogroup/ScenarioGroupMasterdataForm.vue';
import { activeCardData, scenarioGroupData } from '@/scenario/keys';
import { useHeadSafe } from '@unhead/vue';
import { computed, inject } from 'vue';

const scenarioGroupInject = inject(scenarioGroupData);
const scenarioGroup = scenarioGroupInject?.scenarioGroup;

const fields = computed(() => scenarioGroupInject?.fields.value);
const errors = computed(() => scenarioGroupInject?.errors.value);

const activeCardInject = inject(activeCardData);

const cardId = 'scenario-group-masterdata';
const cardLabel = fluent.$t('master-data');

function onDiscardChanges() {
    activeCardInject?.onLeaveEditMode();
    scenarioGroupInject?.discardChanges();
}

function onSave() {
    scenarioGroupInject?.onUpdateScenarioGroup();
    activeCardInject?.onLeaveEditMode();
}

useHeadSafe({
    title: () => `${fluent.$t('master-data')} - ${scenarioGroup?.value?.nameShort} - ${fluent.$t('scenario-group')}`,
});
</script>
