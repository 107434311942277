<template>
    <BaseForm @submit.prevent>
        <template #fields>
            <FieldGroup :title="$t('identifiers')">
                <TextField
                    :value="nameShort"
                    :label="$t('name-short')"
                    :error-messages="nameShortErrors"
                    @input="handleNameShort(($event.target as HTMLInputElement).value)"
                />
                <TextField
                    :value="nameLong"
                    :label="$t('name-long')"
                    :error-messages="nameLongErrors"
                    @input="handleNameLong(($event.target as HTMLInputElement).value)"
                />
            </FieldGroup>
        </template>
        <template #buttons>
            <Button v-if="formIsTouched" size="Base" variant="Danger-Light" @click="onDiscard">
                <template #icon>
                    <UndoIcon />
                </template>
                {{ $t('discard') }}
            </Button>
            <Button v-else size="Base" variant="Neutral-Light" @click="$router.back">
                <template #icon>
                    <UndoIcon />
                </template>
                {{ $t('abort') }}
            </Button>
            <Button
                size="Base"
                variant="Info-Strong"
                :disabled-explanation="saveButtonDisabledExplanation"
                type="submit"
                @click="onSave"
            >
                <template #icon>
                    <AllDoneIcon />
                </template>
                {{ $t('create') }}
            </Button>
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import Button from '@/components/Button/Button.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import FieldGroup from '@/components/Form/FieldGroup.vue';
import TextField from '@/components/Form/TextField.vue';
import AllDoneIcon from '@/components/Icon/AllDoneIcon.vue';
import UndoIcon from '@/components/Icon/UndoIcon.vue';
import { useScenarioGroupCreateForm } from '../composables/useScenarioGroupCreateForm';

const {
    nameShort,
    nameLong,
    nameShortErrors,
    nameLongErrors,
    formIsTouched,
    saveButtonDisabledExplanation,
    handleNameShort,
    handleNameLong,
    onDiscard,
    onSave,
} = useScenarioGroupCreateForm();
</script>
