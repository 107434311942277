import auth from '@/auth/auth';
import {
    DeleteScenarioGroupDocument,
    GetScenarioGroupDocument,
    ScenarioGroupDetailsFragment,
} from '@/generated/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref, Ref, toValue } from 'vue';
import { goToScenarioOverview } from '../scenario';

function setEmptyScenarioGroup() {
    return {
        id: '',
        index: 0,
        nameShort: '',
        nameLong: '',
        intention: '',
        description: '',
        status: '',
        kapmanNumber: '',
        companyGroup: '',
        businessDomain: '',
        department: '',
        responsible: '',
        createdAt: '',
        scenarios: {
            totalCount: 0,
            nodes: [],
        },
        organisationalChanges: {
            id: '',
        },
        changes: {
            id: '',
        },
    };
}

export function useScenarioGroup(scenarioGroupId: Ref<string>) {
    const scenarioGroup: Ref<ScenarioGroupDetailsFragment> = ref(setEmptyScenarioGroup());

    const isAdmin = auth.isAdmin();
    const fluent = useFluent();
    const deleteScenarioGroup = useMutation(DeleteScenarioGroupDocument);

    const { onResult, loading, refetch } = useQuery(
        GetScenarioGroupDocument,
        computed(() => ({ id: toValue(scenarioGroupId) })),
    );

    onResult((result) => {
        const group = result.data?.scenarioGroup;
        if (group) {
            scenarioGroup.value = group;
        }
    });

    async function onDeleteScenarioGroup() {
        if (!isAdmin) return;
        if (!confirm(fluent.$t('confirm-delete-scenario-group', { name: toValue(scenarioGroup).nameShort }))) return;
        const response = await deleteScenarioGroup.mutate({ id: toValue(scenarioGroup).id });
        if (response?.data) {
            goToScenarioOverview();
        }
    }

    const createScenarioDisabledExplanation = computed(() => {
        return isAdmin ? undefined : fluent.$t('no-permission-to-create');
    });

    const deleteScenarioGroupDisabledExplanation = computed(() => {
        if (!isAdmin) return fluent.$t('no-permission-to-edit');
        if (toValue(scenarioGroup).scenarios.totalCount > 0) return fluent.$t('scenario-group-has-scenarios');
        return undefined;
    });

    return {
        scenarioGroup,
        loading,
        createScenarioDisabledExplanation,
        deleteScenarioGroupDisabledExplanation,
        refetch,
        onDeleteScenarioGroup,
    };
}
