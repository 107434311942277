<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>{{ $t('scenarios') }}</template>
        <template #icon>
            <BrainIcon class="size-12" />
        </template>
        <template #buttons>
            <Button
                variant="Info-Strong"
                size="Base"
                :disabled-explanation="createScenarioGroupDisabledExplanation"
                @click="goToCreateScenarioGroup()"
            >
                <template #icon><PlusIcon /></template>
                {{ $t('scenario-group-create') }}
            </Button>
        </template>
        <template #body>
            <div v-if="loading" class="space-y-4">
                <Message status="info">{{ $t('loading-scenario-groups') }}</Message>
            </div>
            <div v-if="!loading && scenarioGroups.length === 0" class="space-y-4">
                <EmptyState>
                    <template #description>
                        {{ $t('no-scenario-groups') }}
                    </template>
                    <template #call-to-action>
                        <Button size="Base" variant="Info-Strong" @click="goToCreateScenarioGroup()">
                            <template #icon>
                                <PlusIcon class="size-5" />
                                {{ $t('scenario-group-create') }}
                            </template>
                        </Button>
                    </template>
                </EmptyState>
            </div>
            <div v-if="!loading && scenarioGroups.length > 0" class="space-y-4">
                <CollapsibleHeadline
                    v-for="[status, statusGroups] of groupStates"
                    :key="status"
                    :initial-is-open="status === 'active'"
                >
                    <template #headline>{{ $t(`scenario-group-status-${status}`) }}</template>
                    <template #content>
                        <div v-if="statusGroups && statusGroups.length > 0" class="space-y-4">
                            <HeadlessCard
                                v-for="scenarioGroup in statusGroups"
                                :key="scenarioGroup.id"
                                :invisible-headline="scenarioGroup.nameShort"
                            >
                                <CardRowColumns :columns-count="2">
                                    <CardRowLinkWithSubtitle
                                        :link-text="scenarioGroup.nameLong"
                                        :link-target="linkToScenarioGroup(scenarioGroup.id)"
                                        :has-border-bottom="true"
                                    >
                                        <template #additional-content>
                                            <ScenarioGroupIndex :index="scenarioGroup.index" :max-digits="5" />
                                            <span class="text-gray-400 font-light">|</span>
                                        </template>
                                        <template #subtitle>
                                            <i18n
                                                v-if="scenarioGroup.scenarios.totalCount > 0"
                                                path="scenario-group-subtitle-styled"
                                                class="text-gray-400 font-light"
                                                tag="div"
                                                :args="{ count: scenarioGroup.scenarios.totalCount }"
                                            >
                                                <template #scenariosTotalCount>
                                                    <span class="text-gray-700 font-bold">
                                                        {{ scenarioGroup.scenarios.totalCount }}
                                                    </span>
                                                </template>
                                                <template #scenariosYearString>
                                                    <template
                                                        v-for="(element, index) in getScenarioGroupYears(
                                                            scenarioGroup.id,
                                                        )"
                                                        :key="index"
                                                    >
                                                        <span class="text-gray-600 font-semibold">
                                                            <template v-if="element.length === 1">
                                                                {{ element[0] }}
                                                            </template>
                                                            <template v-if="element.length === 2">
                                                                {{ element[0] }}
                                                                <span class="text-gray-400">-</span>
                                                                {{ element[1] }}
                                                            </template>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                index <
                                                                getScenarioGroupYears(scenarioGroup.id).length - 1
                                                            "
                                                            class="text-gray-400 font-light pl-[1px]"
                                                        >
                                                            |
                                                        </span>
                                                    </template>
                                                </template>
                                            </i18n>
                                            <div v-else class="text-gray-400 font-light">
                                                {{ $t('no-scenarios-yet') }}
                                            </div>
                                        </template>
                                    </CardRowLinkWithSubtitle>
                                    <div class="flex flex-col self-start py-2 px-4 justify-self-end">
                                        <Badge
                                            size="Extra-Small"
                                            :variant="getScenarioGroupBadgeVariant(status as ScenarioGroupStatus)"
                                            >{{ $t(`scenario-group-status-${status}`) }}</Badge
                                        >
                                        <div></div>
                                    </div>
                                </CardRowColumns>
                            </HeadlessCard>
                        </div>
                        <div v-else class="space-y-4 font-light text-gray-600">
                            ({{ $t('no-scenario-groups-with-this-status') }})
                        </div>
                    </template>
                </CollapsibleHeadline>
            </div>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import Badge from '@/components/Badge/Badge.vue';
import Button from '@/components/Button/Button.vue';
import CardRowColumns from '@/components/Card/CardRowColumns.vue';
import CardRowLinkWithSubtitle from '@/components/Card/CardRowLinkWithSubtitle.vue';
import HeadlessCard from '@/components/Card/HeadlessCard.vue';
import CollapsibleHeadline from '@/components/CollapsibleHeadline/CollapsibleHeadline.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import BrainIcon from '@/components/Icon/BrainIcon.vue';
import PlusIcon from '@/components/Icon/PlusIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import Message from '@/components/Message/Message.vue';
import ScenarioGroupIndex from '@/components/ScenarioGroupIndex/ScenarioGroupIndex.vue';
import { GetScenarioGroupsDocument, ScenarioGroupDetailsFragment } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { getScenarioGroupBadgeVariant, goToCreateScenarioGroup, linkToScenarioGroup } from '../scenario';
import { scenarioGroupStates, ScenarioGroupStatus } from '../types';

const isAdmin = auth.isAdmin();
const fluent = useFluent();

const { result: getScenarioGroups, loading } = useQuery(GetScenarioGroupsDocument);
const scenarioGroups = computed(() => getScenarioGroups.value?.scenarioGroups?.nodes ?? []);
const groupStates = computed(() => {
    const groupedObjects = Object.groupBy(scenarioGroups.value, ({ status }) => status);
    // add map with all states, fill with empty array, if no grouped objects exist
    const stateMap: Map<string, Array<ScenarioGroupDetailsFragment>> = new Map();
    for (const status of scenarioGroupStates) {
        stateMap.set(status, groupedObjects[status] ?? []);
    }
    return stateMap;
});

const createScenarioGroupDisabledExplanation = computed(() => {
    if (!isAdmin) return fluent.$t('no-permission-to-edit');
});

function formatYearList(years: string[]) {
    const result: Array<string[]> = [];
    let startYear = years[0];
    let endYear = years[0];

    for (let i = 1; i < years.length; i++) {
        if (Number(years[i]) === Number(endYear) + 1) {
            endYear = years[i];
        } else {
            if (startYear === endYear) {
                result.push([startYear]);
            } else {
                result.push([startYear, endYear]);
            }
            startYear = years[i];
            endYear = years[i];
        }
    }

    if (startYear === endYear) {
        result.push([startYear]);
    } else {
        result.push([startYear, endYear]);
    }

    return result;
}

function getScenarioGroupYears(id: string) {
    const group = scenarioGroups.value.find((g) => g.id === id);
    if (group && group.scenarios.totalCount > 0) {
        const scenarios = group.scenarios.nodes;
        const scenarioYears = Object.groupBy(scenarios, ({ year }) => year.toString());
        const years = Object.keys(scenarioYears);
        return formatYearList(years);
    }
    return [];
}

useHeadSafe({
    title: () => `${fluent.$t('scenarios')}`,
});
</script>
