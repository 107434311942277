import { OPTIONAL_STRING, REQUIRED_STRING, YEAR } from '@/components/Form/schema';
import { z } from 'zod';

export const scenarioGroupCreateSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: REQUIRED_STRING,
});

export const scenarioCreateSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: REQUIRED_STRING,
    year: YEAR,
});

export const ScenarioGroupSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: REQUIRED_STRING,
    intention: OPTIONAL_STRING,
    description: OPTIONAL_STRING,
    kapmanNumber: OPTIONAL_STRING,
    companyGroup: OPTIONAL_STRING,
    businessDomain: OPTIONAL_STRING,
    department: OPTIONAL_STRING,
    responsible: OPTIONAL_STRING,
});

export type ScenarioGroupSchemaType = z.infer<typeof ScenarioGroupSchema>;

export const ScenarioSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: REQUIRED_STRING,
    year: YEAR,
    intention: OPTIONAL_STRING,
    description: OPTIONAL_STRING,
});

export type ScenarioSchemaType = z.infer<typeof ScenarioSchema>;
