import { ActiveCard, EditableCardStatus } from '@/components/Card/types';
import { CommentChangesStatus } from '@/components/Feed/types';
import { EditableFeedEntry } from '@/feed/types';
import { FeedEntryDataFragment, ScenarioDetailsFragment, ScenarioGroupDetailsFragment } from '@/generated/graphql';
import { InjectionKey, Ref } from 'vue';
import { ScenarioErrors, ScenarioFields, ScenarioGroupErrors, ScenarioGroupFields } from './types';

type ScenarioGroupInjection = {
    scenarioGroup: Ref<ScenarioGroupDetailsFragment | undefined>;
    fields: Ref<ScenarioGroupFields>;
    errors: Ref<ScenarioGroupErrors>;
    onInput: (fieldData: { event: Event; fieldName: string }) => void;
    discardChanges: () => void;
    onUpdateScenarioGroup: () => void;
};

type ScenarioInjection = {
    scenario: Ref<ScenarioDetailsFragment>;
    fields: Ref<ScenarioFields>;
    errors: Ref<ScenarioErrors>;
    onInput: (fieldData: { event: Event; fieldName: string }) => void;
    discardChanges: () => void;
    onUpdateScenario: () => void;
};

type ActiveCardInjection = {
    activeCard: Ref<ActiveCard>;
    onEnterEditMode: (id: string, label: string) => void;
    onLeaveEditMode: () => void;
    showForm: (id: string) => boolean;
    editButtonDisabledMessage: (id: string) => string;
    getCardStatus: (id: string) => EditableCardStatus;
};

type FeedInjection = {
    feedEntries: Ref<FeedEntryDataFragment[]>;
    addFeedEntry: (data: EditableFeedEntry) => void;
    createUserTextEntry: (text: string) => void;
    createCreatedAtEntry: (createdAt: string) => void;
    createStatusChangeEntry: (oldStatus: string, newStatus: string) => void;
};

type OrgFeedInjection = {
    feedEntries: Ref<EditableFeedEntry[]>;
    addFeedEntry: (data: EditableFeedEntry) => void;
    createUserTextEntry: (text: string, hasUnsavedChanges?: CommentChangesStatus) => void;
    setUnsavedChanges: (id: string, status: CommentChangesStatus) => void;
    setText: (id: string, text: string) => void;
    createUnsavedFeedEntry: (text: string) => void;
    onSaveFeed: () => void;
    onDiscardFeedChanges: () => void;
};

export const scenarioGroupData = Symbol() as InjectionKey<ScenarioGroupInjection>;
export const scenarioData = Symbol() as InjectionKey<ScenarioInjection>;
export const activeCardData = Symbol() as InjectionKey<ActiveCardInjection>;
export const feedData = Symbol() as InjectionKey<FeedInjection>;
export const orgFeedData = Symbol() as InjectionKey<OrgFeedInjection>;
