import { CreateFeedDocument } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';

export function useCreateFeed() {
    const createFeedMutation = useMutation(CreateFeedDocument);

    async function createFeed() {
        const response = await createFeedMutation.mutate();
        if (response?.errors) {
            throw new Error(`Could not create Feed: ${response.errors}`);
        }
        if (!response?.data?.createFeed?.feed) {
            throw new Error('Create Feed did not return data.');
        }
        return response.data.createFeed.feed.id;
    }

    return {
        createFeed,
    };
}
