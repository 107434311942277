import { AllocationUsage, TimelineVariant } from '@/components/Timeline/allocation/types';
import { useCreateAllocationUpdateRequestPhases } from '@/components/Timeline/composables/useCreateAllocationUpdateRequestPhases';
import { calculatePhaseUpdate } from '@/components/Timeline/logic/durations';
import { validateAllocationUpdateResponse } from '@/components/Timeline/timeline';
import { AllocationLocationType, AllocationPhaseUpdate, AllocationType } from '@/components/Timeline/Timeline.types';
import {
    AllocationAreaDuplicateDocument,
    AllocationAreaUpdateAndDuplicateDocument,
    AllocationRoomDuplicateDocument,
    AllocationRoomUpdateAndDuplicateDocument,
    ProjectAllocationAreaDuplicateDocument,
    ProjectAllocationAreaUpdateAndDuplicateDocument,
    ProjectAllocationRoomDuplicateDocument,
    ProjectAllocationRoomUpdateAndDuplicateDocument,
    UpdateEventAllocationAreaDocument,
    UpdateEventAllocationRoomDocument,
    UpdateProjectAllocationAreaDocument,
    UpdateProjectAllocationRoomDocument,
} from '@/generated/graphql';
import { useApolloClient, useMutation } from '@vue/apollo-composable';
import { DocumentNode } from 'graphql';
import { DateTime } from 'luxon';
import { computed, Ref, toValue } from 'vue';

export function useUpdateAllocations(
    variantsRef: Ref<TimelineVariant[]>,
    setVariantsRef: (value: TimelineVariant[]) => void,
    usages: Ref<AllocationUsage[]>,
    getVariantQueryDocument: DocumentNode,
) {
    const client = useApolloClient().client;

    const mutations = {
        Project: {
            update: {
                Room: useMutation(UpdateProjectAllocationRoomDocument),
                Area: useMutation(UpdateProjectAllocationAreaDocument),
            },
            duplicate: {
                Room: useMutation(ProjectAllocationRoomDuplicateDocument),
                Area: useMutation(ProjectAllocationAreaDuplicateDocument),
            },
            duplicateAndUpdate: {
                Room: useMutation(ProjectAllocationRoomUpdateAndDuplicateDocument),
                Area: useMutation(ProjectAllocationAreaUpdateAndDuplicateDocument),
            },
        },
        Event: {
            update: {
                Room: useMutation(UpdateEventAllocationRoomDocument),
                Area: useMutation(UpdateEventAllocationAreaDocument),
            },
            duplicate: {
                Room: useMutation(AllocationRoomDuplicateDocument),
                Area: useMutation(AllocationAreaDuplicateDocument),
            },
            duplicateAndUpdate: {
                Room: useMutation(AllocationRoomUpdateAndDuplicateDocument),
                Area: useMutation(AllocationAreaUpdateAndDuplicateDocument),
            },
        },
        None: {
            update: { Room: undefined, Area: undefined },
            duplicate: { Room: undefined, Area: undefined },
            duplicateAndUpdate: { Room: undefined, Area: undefined },
        },
    } satisfies Record<
        AllocationType,
        {
            update: { Room: unknown; Area: unknown };
            duplicate: { Room: unknown; Area: unknown };
            duplicateAndUpdate: { Room: unknown; Area: unknown };
        }
    >;

    async function onUpdatePhases(
        phases: AllocationPhaseUpdate[],
        initialStartDateTime: DateTime,
        initialEndDateTime: DateTime,
        newLocationIds: string[],
        allocationId: string,
        allocationType: AllocationType,
        allocationLocationType: AllocationLocationType,
        allocationVariantId: string,
        onDone: (isSuccess: boolean, phaseUpdates: AllocationPhaseUpdate[]) => void,
    ): Promise<void> {
        const allocation = {
            startDate: initialStartDateTime,
            endDate: initialEndDateTime,
        };

        const { startDateTime, endDateTime } = calculatePhaseUpdate(phases, allocation);

        /* There are 3 possible cases for phase updates:
         * 1) Update only phases, without duplicating the allocation into other locations
         * 2) Only duplicate the allocation into other locations, without updating the phases
         * 3) Update phases + duplicate the allocation into other locations
         */

        const isDuplicated = newLocationIds.length > 0;
        const hasPhaseChanges = phases.some((phase) => phase.cellSpanBefore !== 0 || phase.cellSpanAfter !== 0);
        const hasSpecializationChanges = phases.some((phase) => phase.newSpecialization);

        const { createRequestPhases } = useCreateAllocationUpdateRequestPhases(phases, usages, allocationType);
        const { phasesToCreate, updatePhases, phasesToUpdate, phasesToDelete, remainingPhases } = createRequestPhases();

        // Case 1 (only phase update)
        if (!isDuplicated && (hasPhaseChanges || hasSpecializationChanges)) {
            const updateMutation = mutations[allocationType].update[allocationLocationType];
            if (updateMutation) {
                const response = await updateMutation.mutate({
                    allocationId: toValue(allocationId),
                    start: startDateTime.toISO(),
                    end: endDateTime.toISO(),
                    phasesToCreate,
                    phasesToUpdate: phasesToUpdate.map((phase) => ({
                        id: phase.id,
                        patch: {
                            duration: phase.duration,
                            offset: phase.offset,
                            usageId: phase.usageId,
                            specializationType: phase.specializationType,
                        },
                    })),
                    phasesToDelete,
                });
                const allocation = computed(() => response?.data?.update?.allocation);
                const confirmedPhases = validateAllocationUpdateResponse(allocation.value, remainingPhases);

                const allocationValue = allocation.value;
                if (!allocationValue) {
                    throw new Error('Could not update allocation');
                }

                const newValue = variantsRef.value.map((variant) => {
                    if (variant.id === allocationVariantId) {
                        if (allocationLocationType === 'Room') {
                            return {
                                ...variant,
                                rooms: {
                                    nodes: variant.rooms.nodes.map((room) =>
                                        room.id === toValue(allocationId) ? allocationValue : room,
                                    ),
                                },
                            };
                        }
                        if (allocationLocationType === 'Area') {
                            return {
                                ...variant,
                                areas: {
                                    nodes: variant.areas.nodes.map((area) =>
                                        area.id === toValue(allocationId) ? allocationValue : area,
                                    ),
                                },
                            };
                        }
                    }
                    return variant;
                });
                setVariantsRef(newValue);
                onDone(true, confirmedPhases);
                return;
            }
        }

        const newLocationIdsWithoutPrefix = newLocationIds.map((location) => location.replace(/^room-/, ''));

        // Case 2 (Duplicated, no phase changes)
        if (isDuplicated && !(hasPhaseChanges || hasSpecializationChanges)) {
            const duplicateMutation = mutations[allocationType].duplicate[allocationLocationType];

            if (duplicateMutation) {
                const response = await duplicateMutation.mutate({
                    variantId: allocationVariantId,
                    allocationId,
                    locationIds: newLocationIdsWithoutPrefix,
                });

                if (response?.errors) {
                    throw new Error(`Duplicating allocations failed ${JSON.stringify(response.errors, null, 2)}`);
                }

                const duplicatedAllocations = response?.data?.duplicate?.allocations;
                if (duplicatedAllocations) {
                    const newValue = variantsRef.value.map((v) => {
                        if (v.id === allocationVariantId) {
                            if (allocationLocationType === 'Room') {
                                return {
                                    ...v,
                                    rooms: {
                                        nodes: [...v.rooms.nodes, ...duplicatedAllocations],
                                    },
                                };
                            }
                            if (allocationLocationType === 'Area') {
                                return {
                                    ...v,
                                    areas: {
                                        nodes: [...v.areas.nodes, ...duplicatedAllocations],
                                    },
                                };
                            }
                        }
                        return v;
                    });
                    setVariantsRef(newValue);
                }

                onDone(true, updatePhases);
                return;
            }
        }

        // Case 3 (Duplicated + phase changes)
        const duplicateAndUpdateMutation = mutations[allocationType].duplicateAndUpdate[allocationLocationType];

        if (duplicateAndUpdateMutation) {
            const response = await duplicateAndUpdateMutation.mutate({
                allocationId: allocationId,
                variantId: allocationVariantId,
                updateRoomPhases: phasesToUpdate,
                deleteRoomPhases: phasesToDelete.map((p) => p.id),
                createRoomPhases: phasesToCreate,
                locationIds: newLocationIdsWithoutPrefix,
                updateAllocation: {
                    start: startDateTime.toISO(),
                    end: endDateTime.toISO(),
                },
            });

            if (response?.errors) {
                throw new Error(
                    `Duplicating allocations and updating phases failed ${JSON.stringify(response.errors, null, 2)}`,
                );
            }

            const updatedAllocationsResponse = response?.data?.updateAndDuplicate?.allocations;

            if (!updatedAllocationsResponse) {
                throw new Error('Missing updated allocations');
            }

            const currentAllocation = updatedAllocationsResponse.find((allocation) => allocation.id === allocationId);

            if (!currentAllocation) {
                throw new Error('Missing current allocation in response');
            }

            const currentAllocationUpdatedPhases = currentAllocation.phases.nodes;

            if (currentAllocationUpdatedPhases.length !== remainingPhases.length) {
                throw new Error('Unexpected mismatch of frontend phases length and updated phases in response');
            }

            // FIXME code duplication from confirmed phases above??
            const confirmedPhases = remainingPhases.map((phase, index) => {
                const specialization =
                    phase.newSpecialization !== phase.specialization ? phase.newSpecialization : phase.specialization;
                return {
                    ...phase,
                    id: currentAllocationUpdatedPhases[index].id,
                    cellSpan: phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter,
                    cellSpanBefore: 0,
                    cellSpanAfter: 0,
                    specialization,
                };
            });

            // TODO replace this query by directly returning proper values from updateAndDuplicate graphql
            const variantRefetch = await client.query({
                query: getVariantQueryDocument,
                variables: { variantId: allocationVariantId },
            });
            const variant = variantRefetch.data.variant;
            if (variant) {
                const newValue = variantsRef.value.map((v) => (v.id === allocationVariantId ? variant : v));
                setVariantsRef(newValue);
            }
            onDone(true, confirmedPhases);
        }
    }

    return {
        onUpdatePhases,
    };
}
