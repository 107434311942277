<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>{{ $t('profile') }}</template>
        <template #buttons>
            <Button :size="'Base'" :variant="'Neutral-Strong'" @click="auth.logout()">
                <template #icon>
                    <LogoutIcon />
                </template>
                Logout
            </Button>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import LogoutIcon from '@/components/Icon/LogoutIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';

useHeadSafe({ title: () => `${auth.getUser().name} - ${$t('users')}` });
</script>
