<template>
    <div v-if="series" class="space-y-4">
        <FlexibleCard headline-id="orgcat" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('orgcat')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('orgcat')"
                    @enter-edit-mode="
                        cardFunctions?.updateCard('orgcat', $t('organisational-categorization'), 'Edit-Untouched')
                    "
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectSeriesOrgSchema)"
                    @save="dataFunctions?.save(ProjectSeriesOrgSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('organisational-categorization') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectSeriesOrgForm v-if="showForm('orgcat')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('company-group')"
                            :text="valueToString(series.companyGroup)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('business-domain')"
                            :text="valueToString(series.businessDomain)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('department')"
                            :text="valueToString(series.department)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('organisational-changes')"
                            :text="valueToString(series.organisationalChanges)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { valueToString } from '@/forms/forms';
import { $t } from '@/plugins/fluent';
import ProjectSeriesOrgForm from '@/project/forms/series/ProjectSeriesOrgForm.vue';
import { activeCardData, activeCardFunctions, formFunctions, projectSeriesData } from '@/project/keys';
import { ProjectSeriesOrgSchema } from '@/project/schemas';
import { useHeadSafe } from '@unhead/vue';
import { inject, ref } from 'vue';
const data = inject(projectSeriesData);
const series = data?.series;

useHeadSafe({ title: () => `${$t('organisational-structure')} - ${series?.value.nameShort} - ${$t('project-wiki')}` });

const dataFunctions = inject(formFunctions);
const cardFunctions = inject(activeCardFunctions);
const activeCard = inject(activeCardData);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard ?? ref(emptyActiveCard()));
</script>
