<template>
    <div v-if="series && errors" class="space-y-4">
        <FlexibleCard headline-id="identifiers" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('identifiers')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('identifiers')"
                    @enter-edit-mode="cardFunctions?.updateCard('identifiers', $t('identifiers'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectSeriesIdentifiersSchema)"
                    @save="dataFunctions?.save(ProjectSeriesIdentifiersSchema, true)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('identifiers') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectSeriesIdentifiersForm v-if="showForm('identifiers')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('name-short')"
                            :text="valueToString(series.nameShort)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('name-long')"
                            :text="valueToString(series.nameLong)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes')"
                            :text="valueToString(series.notes)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('description')"
                            :text="valueToString(series.description)"
                            :has-border-bottom="true"
                        />
                        <CardRowAttachmentGroup
                            v-if="series.websites && series.websites.links?.nodes.length"
                            :label="$t('other-systems')"
                            :items="toCardRowAttachmentLink(series.websites.links.nodes)"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('project-websites')" text="-" :has-border-bottom="true" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="typeDefinitions" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('typeDefinitions')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('typeDefinitions')"
                    @enter-edit-mode="
                        cardFunctions?.updateCard('typeDefinitions', $t('type-definition'), 'Edit-Untouched')
                    "
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectSeriesTypeDefSchema)"
                    @save="dataFunctions?.save(ProjectSeriesTypeDefSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('type-definition') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectSeriesTypeDefinitionForm v-if="showForm('typeDefinitions')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('project-category')"
                            :text="valueToString(series.category)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('project-type')"
                            :text="valueToString(series.type)"
                            :has-border-bottom="true"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowAttachmentGroup from '@/components/Card/CardRowAttachmentGroup.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import { toCardRowAttachmentLink } from '@/components/MultiLink/multilink';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { valueToString } from '@/forms/forms';
import { $t } from '@/plugins/fluent';
import ProjectSeriesIdentifiersForm from '@/project/forms/series/ProjectSeriesIdentifiersForm.vue';
import ProjectSeriesTypeDefinitionForm from '@/project/forms/series/ProjectSeriesTypeDefinitionForm.vue';
import { activeCardData, activeCardFunctions, formFunctions, projectSeriesData } from '@/project/keys';
import { ProjectSeriesIdentifiersSchema, ProjectSeriesTypeDefSchema } from '@/project/schemas';
import { useHeadSafe } from '@unhead/vue';
import { inject, ref } from 'vue';

const data = inject(projectSeriesData);
const series = data?.series;
const errors = data?.errors;

useHeadSafe({ title: () => `${$t('master-data')} - ${series?.value.nameShort} - ${$t('project-wiki')}` });

const dataFunctions = inject(formFunctions);
const cardFunctions = inject(activeCardFunctions);
const activeCard = inject(activeCardData);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard ?? ref(emptyActiveCard()));
</script>
