import { CreateScenarioDocument } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';

export function useCreateScenario() {
    const createScenarioMutation = useMutation(CreateScenarioDocument);

    async function createScenario(
        scenarioGroupId: string,
        nameShort: string,
        nameLong: string,
        year: number,
        feedId: string,
    ) {
        const response = await createScenarioMutation.mutate({
            scenarioGroupId,
            nameLong,
            nameShort,
            year,
            changesId: feedId,
        });
        if (response?.errors) {
            throw new Error(`Could not create Scenario: ${response.errors}`);
        }
        if (!response?.data?.createScenario?.scenario) {
            throw new Error('Create scenario did not return data');
        }
        return response.data.createScenario.scenario;
    }

    return {
        createScenario,
    };
}
