import auth from '@/auth/auth';
import { config } from '@/config';
import fragments from '@/generated/graphql_fragments.json';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

const httpLink = createHttpLink({
    uri: config.graphqlUrl,
});

const authLink = setContext((_, { headers }) => ({
    headers: {
        ...headers,
        authorization: auth.isLoggedIn() ? `Bearer ${auth.getToken()}` : '',
    },
}));

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            // client error message on missing jwt token
            if (err.message === 'Unauthorized') {
                auth.logoutAndRedirectToLogin();
            }
            // error in jwt verification process from package express-jwt
            // handled by express middleware in backend:
            // https://www.graphile.org/postgraphile/jwk-verification/#basic-error-handling
            if (err.extensions) {
                switch (err.extensions.code) {
                    // Apollo Server sets code to UNAUTHENTICATED
                    // when an AuthenticationError is thrown in a resolver
                    case 'UNAUTHENTICATED': {
                        auth.logoutAndRedirectToLogin();
                        break;
                    }
                    case 'UnauthorizedError': {
                        auth.logoutAndRedirectToLogin();
                        break;
                    }
                }
            }
        }
    }
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
        throw new Error(`[Network error]: ${networkError}, operation: ${operation.operationName}`);
    }
});

const apolloRetryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 2,
        retryIf: (error, _operation) => !!error,
    },
});

const apolloClient = new ApolloClient({
    connectToDevTools: config.debug,
    link: from([authLink, apolloRetryLink, errorLink, httpLink]),
    cache: new InMemoryCache({ possibleTypes: fragments.possibleTypes }),
    defaultOptions: {
        // "watchQuery" applies to all usages of "useQuery". We want to do a network-request to make sure we always have the latest data (even if the apollo cache is stale)
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
        },
        // "query" applies to manual queries executed with the apolloClient itself. We hardly use this anywhere.
        query: {
            fetchPolicy: 'network-only',
        },
    },
});

export default apolloClient;
