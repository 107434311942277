import auth from '@/auth/auth';
import { BadgeDropDownStatus, BadgeDropDownValue } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import { ScenarioGroupDetailsFragment, UpdateScenarioGroupStatusDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, Ref, toValue } from 'vue';
import { getScenarioGroupBadgeVariant } from '../scenario';
import { scenarioGroupStates, ScenarioGroupStatus } from '../types';

export function useStatusBadge(scenarioGroup: Ref<ScenarioGroupDetailsFragment>) {
    const fluent = useFluent();
    const isAdmin = auth.isAdmin();

    const status = computed(() => toValue(scenarioGroup).status ?? 'active');
    const updateScenarioGroupStatus = useMutation(UpdateScenarioGroupStatusDocument);

    const statusBadgeDisabledExplanation = computed(() => {
        return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
    });

    function getStatusBadgeCurrentValue(): BadgeDropDownValue & { tooltip?: string } {
        return {
            id: toValue(status),
            label: fluent.$t(`scenario-group-status-${toValue(status)}`),
            variant: getScenarioGroupBadgeVariant(toValue(status) as ScenarioGroupStatus),
            tooltip: undefined,
        };
    }

    function getStatusBadgeItems(): Array<BadgeDropDownValue & { isSelected: boolean }> {
        return scenarioGroupStates.map((s) => ({
            id: s,
            label: $t(`scenario-group-status-${s}`),
            variant: getScenarioGroupBadgeVariant(s),
            isSelected: toValue(status) === s,
        }));
    }

    function getStatusBadgeStatus(): BadgeDropDownStatus {
        if (statusBadgeDisabledExplanation.value) return 'Disabled';
        if (updateScenarioGroupStatus.loading.value) return 'Loading';
        if (updateScenarioGroupStatus.error.value) return 'Retry';
        return 'Default';
    }

    async function handleStatusBadgeChange(newStatus: string) {
        const response = await updateScenarioGroupStatus.mutate({
            scenarioGroupId: toValue(scenarioGroup).id,
            status: newStatus,
        });
        if (response?.errors) {
            throw new Error(`Could not update ScenarioGroup status: ${response.errors}`);
        }
        if (!response?.data?.updateScenarioGroup?.scenarioGroup) {
            throw new Error('Update ScenarioGroup status did not return data.');
        }
        return response.data.updateScenarioGroup.scenarioGroup.status;
    }

    return {
        statusBadgeDisabledExplanation,
        getStatusBadgeCurrentValue,
        getStatusBadgeItems,
        getStatusBadgeStatus,
        handleStatusBadgeChange,
    };
}
