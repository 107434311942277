import { requireAdmin } from '@/plugins/router';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import ScenarioCreatePage from './pages/ScenarioCreatePage.vue';
import ScenarioGroupCreatePage from './pages/ScenarioGroupCreatePage.vue';
import ScenarioGroupPage from './pages/ScenarioGroupPage.vue';
import ScenarioOverviewPage from './pages/ScenarioOverviewPage.vue';
import ScenarioPage from './pages/ScenarioPage.vue';
import ScenarioChangesTab from './tabs/scenario/ScenarioChangesTab.vue';
import ScenarioMasterdataTab from './tabs/scenario/ScenarioMasterdataTab.vue';
import ScenarioGroupChangesTab from './tabs/scenariogroup/ScenarioGroupChangesTab.vue';
import ScenarioGroupMasterdataTab from './tabs/scenariogroup/ScenarioGroupMasterdataTab.vue';
import ScenarioGroupOrganisationTab from './tabs/scenariogroup/ScenarioGroupOrganisationTab.vue';
import ScenarioGroupScenariosTab from './tabs/scenariogroup/ScenarioGroupScenariosTab.vue';

export const scenarioRoutes = <RouteRecordRaw[]>[
    {
        path: '/scenarios',
        children: [
            {
                path: '',
                name: 'scenarios-overview',
                component: ScenarioOverviewPage,
            },
            {
                path: 'scenario-group-create',
                name: 'scenario-group-create',
                beforeEnter: requireAdmin,
                component: ScenarioGroupCreatePage,
            },
            {
                path: 'scenario-create',
                name: 'scenario-create',
                props: (route: RouteLocationNormalized) => ({
                    scenarioGroupId: route.query.scenarioGroupId,
                    scenarioGroupName: route.query.scenarioGroupName,
                    scenarioGroupIndex: Number(route.query.scenarioGroupIndex),
                }),
                beforeEnter: requireAdmin,
                component: ScenarioCreatePage,
            },
            {
                path: 'group/:scenarioGroupId',
                component: ScenarioGroupPage,
                props: true,
                children: [
                    {
                        path: '',
                        redirect: { name: 'scenario-group-scenarios-tab' },
                    },
                    {
                        path: 'scenarios',
                        name: 'scenario-group-scenarios-tab',
                        component: ScenarioGroupScenariosTab,
                    },
                    {
                        path: 'masterdata',
                        name: 'scenario-group-masterdata-tab',
                        component: ScenarioGroupMasterdataTab,
                    },
                    {
                        path: 'organisation',
                        name: 'scenario-group-organisation-tab',
                        component: ScenarioGroupOrganisationTab,
                    },
                    {
                        path: 'changes',
                        name: 'scenario-group-changes-tab',
                        component: ScenarioGroupChangesTab,
                    },
                ],
            },
            {
                path: 'scenario/:scenarioId',
                component: ScenarioPage,
                props: true,
                children: [
                    {
                        path: '',
                        redirect: { name: 'scenario-masterdata-tab' },
                    },
                    {
                        path: 'masterdata',
                        name: 'scenario-masterdata-tab',
                        component: ScenarioMasterdataTab,
                    },
                    {
                        path: 'changes',
                        name: 'scenario-changes-tab',
                        component: ScenarioChangesTab,
                    },
                ],
            },
        ],
    },
];
