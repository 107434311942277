<template>
    <section class="flex flex-row gap-2">
        <div class="px-1 flex flex-col items-center">
            <div
                class="flex place-items-center place-content-center bg-gray-200 text-gray-600 rounded-full w-6 h-6 shrink-0"
            >
                <CalendarAddIcon class="text-gray-600 w-3 h-3" />
            </div>

            <div class="border-l border-l-dashed border-l-gray-300 h-full w-px mt-2 translate-x-[0.5px]" />
        </div>

        <div class="pb-6 w-full flex flex-col pt-0.5">
            <TextAreaField class="mb-2" :label="$t('new-comment')" :value="commentRef" :rows="4" @input="onInput" />

            <div class="place-self-end">
                <Button
                    size="Small"
                    variant="Success-Strong"
                    :disabled-explanation="addCommentDisabledExplanation"
                    @click="onClick"
                >
                    <template #icon>
                        <PlusIcon class="w-5 h-5" />
                    </template>
                    {{ $t('add-comment') }}
                </Button>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import fluent from '@/plugins/fluent';
import { computed, ref } from 'vue';
import Button from '../Button/Button.vue';
import TextAreaField from '../Form/TextAreaField.vue';
import CalendarAddIcon from '../Icon/CalendarAddIcon.vue';
import PlusIcon from '../Icon/PlusIcon.vue';

type AddDateCommentEmits = {
    (e: 'create-comment', value: string): void;
    (e: 'input', v: Event): void;
};

const emit = defineEmits<AddDateCommentEmits>();

const commentRef = ref('');

function onClick() {
    emit('create-comment', commentRef.value);
    commentRef.value = '';
}

function onInput(event: Event) {
    commentRef.value = (event.target as HTMLInputElement).value;
    emit('input', event);
}

const addCommentDisabledExplanation = computed(() =>
    commentRef.value === '' ? fluent.$t('comment-is-empty') : undefined,
);
</script>
