<template>
    <div
        class="py-2 px-4 items-start grid gap-4"
        :class="{ 'border-b border-b-gray-200': props.hasBorderBottom }"
        :style="gridTemplateColumnsStyle"
    >
        <strong class="text-sm font-medium text-gray-500 flex flex-row items-center gap-1">
            {{ props.label }}
            <HelpButton v-if="props.helpText" :text="props.helpText" />
        </strong>

        <p class="text-sm text-gray-900 flex justify-between">
            <TextLink :to="linkTarget">
                {{ props.linkText }}
            </TextLink>
            <Tooltip v-if="isExternal" :id="externalLinkIconTooltipId">
                <template #trigger>
                    <ExternalLinkIcon
                        class="text-gray-500"
                        aria-hidden="true"
                        :title="$t('external-link')"
                        :aria-labelledby="externalLinkIconTooltipId"
                    />
                </template>
                <template #text>{{ $t('external-link') }}</template>
            </Tooltip>
        </p>
    </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import HelpButton from '../HelpButton/HelpButton.vue';
import ExternalLinkIcon from '../Icon/ExternalLinkIcon.vue';
import TextLink from '../TextLink/TextLink.vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type CardRowLinkProps = {
    label: string;
    labelWidthInRem?: number;
    helpText?: string;
    linkTarget: RouteLocationRaw;
    linkText: string;
    hasBorderBottom: boolean;
};

const props = defineProps<CardRowLinkProps>();

const gridTemplateColumnsStyle = computed(() => {
    return `grid-template-columns: ${props.labelWidthInRem ?? 15}rem 1fr;`;
});

const isExternal = computed(() => typeof props.linkTarget === 'string' && props.linkTarget.startsWith('http'));
const externalLinkIconTooltipId = nanoid();
</script>
