import { CreateScenarioGroupDocument } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';

export function useCreateScenarioGroup() {
    const createScenarioGroupMutation = useMutation(CreateScenarioGroupDocument);

    async function createScenarioGroup(
        nameShort: string,
        nameLong: string,
        changesId: string,
        organisationalChangesId: string,
    ) {
        const response = await createScenarioGroupMutation.mutate({
            nameShort,
            nameLong,
            changesId,
            organisationalChangesId,
        });
        if (response?.errors) {
            throw new Error(`Could not create ScenarioGroup: ${response.errors}`);
        }
        if (!response?.data?.createScenarioGroup?.scenarioGroup) {
            throw new Error('Create ScenarioGroup did not return data.');
        }
        return response.data.createScenarioGroup.scenarioGroup;
    }

    return {
        createScenarioGroup,
    };
}
