<template>
    <div v-if="series" class="space-y-4">
        <FlexibleCard headline-id="orgcat" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('orgcat')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('orgcat')"
                    @enter-edit-mode="
                        $emit('enterEditMode', { id: 'orgcat', label: $t('organisational-categorization') })
                    "
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'orgcat')"
                    @save="$emit('save', 'orgcat')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('organisational-categorization') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <OrgCatForm
                        v-if="showForm('orgcat')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('company-group')"
                            :text="valueToString(series.companyGroup)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('business-domain')"
                            :text="valueToString(series.businessDomain)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('department')"
                            :text="valueToString(series.department)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('organisational-changes')"
                            :text="valueToString(series.organisationalChanges)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import OrgCatForm from '@/event/forms/series/OrgCatForm.vue';
import { EventSeriesDetails, EventSeriesDetailsErrors } from '@/event/types';
import { valueToString } from '@/forms/forms';
import { computed } from 'vue';

const props = defineProps<{
    series: EventSeriesDetails;
    errors: EventSeriesDetailsErrors;
    activeCard: ActiveCard;
}>();

defineEmits<{
    (e: 'enterEditMode', v: { id: string; label: string }): void;
    (e: 'leaveEditMode'): void;
    (e: 'discardChanges', v: string): void;
    (e: 'save', v: string): void;
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
}>();

const activeCard = computed(() => props.activeCard);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard);
</script>
