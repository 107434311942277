<template>
    <details :aria-expanded="isOpen" :aria-controls="contentId" :open="isOpen" @toggle="onToggle">
        <summary
            role="button"
            className="border-bottom remove-default-arrow rounded p-2 text-2xl font-semibold hover:bg-gray-700 hover:text-gray-50 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700 active:bg-gray-950 active:text-gray-50 pr-8 relative -mx-2"
        >
            <slot name="headline" />

            <div class="size-8 absolute top-1/2 -translate-y-1/2 right-0 flex items-center justify-center">
                <ChevronRightIcon
                    class="size-6 rounded-sm transition-transform"
                    :class="[isOpen ? '-rotate-90' : 'rotate-90']"
                />
            </div>
        </summary>

        <div :id="contentId" class="mt-2">
            <slot name="content" />
        </div>
    </details>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { ref } from 'vue';
import ChevronRightIcon from '../Icon/ChevronRightIcon.vue';

type CollapsibleHeadlineProps = {
    initialIsOpen: boolean;
};

const props = defineProps<CollapsibleHeadlineProps>();

const contentId = nanoid();

const isOpen = ref(props.initialIsOpen);

function onToggle(event: ToggleEvent) {
    isOpen.value = (event.target as HTMLDetailsElement).open;
}
</script>

<style scoped>
.remove-default-arrow {
    display: block; /* Per spec, the <summary> element uses display: list-item;, so this removes the default arrow */
}

/* Pseudo-element so that it has no border-radius as a normal border would have */
.border-bottom {
    margin-bottom: 0.0625rem; /* Mimic border width */
    position: relative;
}
.border-bottom::after {
    background: theme('colors.gray.300');
    width: calc(100% - 0.5rem - 0.5rem); /* Negative margins left and right */
    height: 0.0625rem;
    display: block;
    content: '';
    bottom: -1px;
    left: 0.5rem; /* Negative margin left */
    position: absolute;
}
</style>
