import auth from '@/auth/auth';
import { REQUIRED_STRING, YEAR } from '@/components/Form/schema';
import { useCreateFeed } from '@/feed/composables/useCreateFeed';
import { CreateCreatedAtEntryDocument, GetScenarioByNameShortDocument } from '@/generated/graphql';
import { useMyUser } from '@/user/composables/useMyUser';
import { useApolloClient } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref, Ref, toValue } from 'vue';
import { useRouter } from 'vue-router';
import { scenarioCreateSchema } from '../forms/schemas';
import { gotToScenario } from '../scenario';
import { useCreateScenario } from './useCreateScenario';

export function useScenarioCreateForm(scenarioGroupId: Ref<string>) {
    const isAdmin = auth.isAdmin();
    const fluent = useFluent();
    const client = useApolloClient().client;
    const { currentUser } = useMyUser();
    const router = useRouter();

    const { createFeed } = useCreateFeed();
    const { createScenario } = useCreateScenario();

    const nameShort = ref('');
    const nameLong = ref('');
    const year = ref();
    const feedId = ref('');

    const formIsTouched = computed(() => {
        if (
            nameShort.value.length > 0 ||
            nameLong.value.length > 0 ||
            (year.value && year.value.toString().length > 0)
        ) {
            return true;
        }
        return false;
    });

    const nameShortErrors: Ref<string[]> = ref([]);
    const nameLongErrors: Ref<string[]> = ref([]);
    const yearErrors: Ref<string[]> = ref([]);

    function handleNameShort(value: string) {
        const parse = REQUIRED_STRING.safeParse(value);
        if (parse.success) {
            nameShortErrors.value = [];
            nameShort.value = parse.data;
        } else {
            nameShortErrors.value = parse.error.format()._errors;
            nameShort.value = value;
        }
    }

    function handleNameLong(value: string) {
        const parse = REQUIRED_STRING.safeParse(value);
        if (parse.success) {
            nameLongErrors.value = [];
            nameLong.value = parse.data;
        } else {
            nameLongErrors.value = parse.error.format()._errors;
            nameLong.value = value;
        }
    }

    function handleYear(value: string) {
        const parse = YEAR.safeParse(value);
        if (parse.success) {
            yearErrors.value = [];
            year.value = parse.data;
        } else {
            yearErrors.value = parse.error.format()._errors;
            year.value = value;
        }
    }

    const saveButtonDisabledExplanation = computed(() => {
        return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
    });

    async function onSave() {
        const data = {
            scenarioGroupId: toValue(scenarioGroupId),
            nameLong: nameLong.value,
            nameShort: nameShort.value,
            year: year.value,
        };

        // check if nameShort already exists
        const nameShortResponse = await client.query({
            query: GetScenarioByNameShortDocument,
            variables: { nameShort: nameShort.value },
        });
        if (nameShortResponse.data.scenarioByNameShort?.id) {
            nameShortErrors.value = [fluent.$t('nameshort-already-taken')];
            return;
        }

        const parse = scenarioCreateSchema.safeParse(data);
        if (!parse.success) {
            const flattenedErrors = parse.error.flatten().fieldErrors;
            nameShortErrors.value = flattenedErrors.nameShort ?? [];
            nameLongErrors.value = flattenedErrors.nameLong ?? [];
            yearErrors.value = flattenedErrors.year ?? [];
            return;
        }
        // create feed and entry
        const createFeedResult = await createFeed();
        feedId.value = createFeedResult;
        if (feedId.value) {
            const { nameShort, nameLong, year } = data;
            const { id, createdAt } = await createScenario(
                toValue(scenarioGroupId),
                nameShort,
                nameLong,
                year,
                feedId.value,
            );
            if (id && createdAt) {
                const createEntryResponse = await client.mutate({
                    mutation: CreateCreatedAtEntryDocument,
                    variables: {
                        feedId: feedId.value,
                        createdById: currentUser.value.id,
                        createdByName: currentUser.value.name,
                        type: 'created-at',
                        objectCreatedAt: createdAt,
                    },
                });
                if (createEntryResponse.errors) {
                    throw new Error('Could not created created entry for new ScenarioGroup');
                }
                gotToScenario(id);
            }
        }
    }

    function onDiscard() {
        if (formIsTouched.value) {
            if (!confirm(fluent.$t('leave-page-warning'))) return;
        }
        router.back();
    }

    return {
        nameShort,
        nameLong,
        year,
        nameShortErrors,
        nameLongErrors,
        yearErrors,
        saveButtonDisabledExplanation,
        formIsTouched,
        handleNameShort,
        handleNameLong,
        handleYear,
        onDiscard,
        onSave,
    };
}
