import auth from '@/auth/auth';
import { REQUIRED_STRING } from '@/components/Form/schema';
import { useCreateFeed } from '@/feed/composables/useCreateFeed';
import { CreateCreatedAtEntryDocument, GetScenarioGroupByNameShortDocument } from '@/generated/graphql';
import { useMyUser } from '@/user/composables/useMyUser';
import { useApolloClient } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { scenarioGroupCreateSchema } from '../forms/schemas';
import { gotToScenarioGroup } from '../scenario';
import { useCreateScenarioGroup } from './useCreateScenarioGroup';

export function useScenarioGroupCreateForm() {
    const isAdmin = auth.isAdmin();
    const fluent = useFluent();
    const client = useApolloClient().client;
    const router = useRouter();
    const { currentUser } = useMyUser();

    const nameShort = ref('');
    const nameLong = ref('');

    const formIsTouched = computed(() => nameShort.value.length > 0 || nameLong.value.length > 0);

    const nameShortErrors: Ref<string[]> = ref([]);
    const nameLongErrors: Ref<string[]> = ref([]);

    function handleNameShort(value: string) {
        const parse = REQUIRED_STRING.safeParse(value);
        if (parse.success) {
            nameShortErrors.value = [];
            nameShort.value = parse.data;
        } else {
            nameShortErrors.value = parse.error.format()._errors;
            nameShort.value = value;
        }
    }

    function handleNameLong(value: string) {
        const parse = REQUIRED_STRING.safeParse(value);
        if (parse.success) {
            nameLongErrors.value = [];
            nameLong.value = parse.data;
        } else {
            nameLongErrors.value = parse.error.format()._errors;
            nameLong.value = value;
        }
    }

    const saveButtonDisabledExplanation = computed(() => {
        return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
    });

    const { createFeed } = useCreateFeed();
    const { createScenarioGroup } = useCreateScenarioGroup();
    const feedIdChanges = ref('');

    function onDiscard() {
        if (formIsTouched.value) {
            if (!confirm(fluent.$t('leave-page-warning'))) return;
        }
        router.back();
    }

    async function onSave() {
        const data = {
            nameLong: nameLong.value,
            nameShort: nameShort.value,
        };

        // check if nameShort already exists
        const nameShortResponse = await client.query({
            query: GetScenarioGroupByNameShortDocument,
            variables: { nameShort: nameShort.value },
        });

        if (nameShortResponse.data.scenarioGroupByNameShort?.id) {
            nameShortErrors.value = [fluent.$t('nameshort-already-taken')];
            return;
        } else {
            nameShortErrors.value = [];
        }
        const parse = scenarioGroupCreateSchema.safeParse(data);
        if (!parse.success) {
            const flattenedErrors = parse.error.flatten().fieldErrors;
            nameShortErrors.value = flattenedErrors.nameShort ?? [];
            nameLongErrors.value = flattenedErrors.nameLong ?? [];
            return;
        }
        // create feed and entry
        const feedIdResult = await createFeed();
        feedIdChanges.value = feedIdResult;
        const feedIdOrganisationalChanges = await createFeed();
        const { id, createdAt } = await createScenarioGroup(
            data.nameShort,
            data.nameLong,
            feedIdChanges.value,
            feedIdOrganisationalChanges,
        );
        if (id && createdAt) {
            const createEntryResponse = await client.mutate({
                mutation: CreateCreatedAtEntryDocument,
                variables: {
                    feedId: feedIdChanges.value,
                    createdById: currentUser.value.id,
                    createdByName: currentUser.value.name,
                    type: 'created-at',
                    objectCreatedAt: createdAt,
                },
            });
            if (createEntryResponse.errors) {
                throw new Error('Could not created created entry for new ScenarioGroup');
            }
            gotToScenarioGroup(id);
        }
    }

    return {
        nameShort,
        nameLong,
        nameShortErrors,
        nameLongErrors,
        formIsTouched,
        saveButtonDisabledExplanation,
        handleNameShort,
        handleNameLong,
        onDiscard,
        onSave,
    };
}
