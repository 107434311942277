<template>
    <FlexibleCard
        v-if="scenarioGroup && scenarioGroup.id && activeCardInject && scenarioGroupInject"
        :headline-id="cardId"
        expandable-status="Not-Collapsible"
    >
        <template #header="{ headlineId }">
            <CardHeaderEditable
                :status="activeCardInject.getCardStatus(cardId)"
                :edit-button-disabled-explanation="activeCardInject.editButtonDisabledMessage(cardId)"
                @enter-edit-mode="activeCardInject.onEnterEditMode(cardId, cardLabel)"
                @leave-edit-mode="activeCardInject.onLeaveEditMode"
                @discard-changes="onDiscardChanges"
                @save="onSave"
            >
                <CardHeadline :headline-id="headlineId">
                    {{ cardLabel }}
                </CardHeadline>
            </CardHeaderEditable>
        </template>
        <template #body>
            <ContentFormTransition>
                <ScenarioGroupOrganisationForm
                    v-if="activeCardInject.showForm(cardId) && fields && errors"
                    :fields="fields"
                    :errors="errors"
                    :org-feed-entries="orgFeedEntries"
                    class="p-5"
                    @on-input="scenarioGroupInject.onInput($event)"
                />
                <div v-else>
                    <CardRowText
                        :label="$t('company-group')"
                        :text="valueToString(scenarioGroup.companyGroup)"
                        :has-border-bottom="false"
                    ></CardRowText>
                    <CardRowText
                        :label="$t('business-domain')"
                        :text="valueToString(scenarioGroup.businessDomain)"
                        :has-border-bottom="false"
                    />
                    <CardRowText
                        :label="$t('department')"
                        :text="valueToString(scenarioGroup.department)"
                        :has-border-bottom="false"
                    />
                    <CardRowText
                        :label="$t('responsible')"
                        :text="valueToString(scenarioGroup.responsible)"
                        :has-border-bottom="false"
                    />
                    <CardRowText
                        v-if="orgFeedEntries.length > 0"
                        :label="$t('organisational-changes')"
                        text=""
                        :has-border-bottom="false"
                    />
                    <div v-if="orgFeedEntries.length > 0" class="py-2 px-4">
                        <Feed>
                            <template v-for="entry in orgFeedEntries" :key="entry.id">
                                <DateCommentEntry
                                    v-if="entry.type === 'user-text'"
                                    :text="entry.text"
                                    :image-url="getImageUrl(entry.createdBy?.icon?.original)"
                                    :name="entry.createdByName"
                                    :date-time="DateTime.fromISO(entry.createdAt)"
                                    :is-editable="false"
                                    has-unsaved-changes="None"
                                />
                            </template>
                        </Feed>
                    </div>
                    <CardRowText v-else :label="$t('organisational-changes')" text="-" :has-border-bottom="true" />
                </div>
            </ContentFormTransition>
        </template>
    </FlexibleCard>
</template>
<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import DateCommentEntry from '@/components/Feed/DateCommentEntry.vue';
import Feed from '@/components/Feed/Feed.vue';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { valueToString } from '@/forms/forms';
import fluent from '@/plugins/fluent';
import ScenarioGroupOrganisationForm from '@/scenario/forms/scenariogroup/ScenarioGroupOrganisationForm.vue';
import { activeCardData, orgFeedData, scenarioGroupData } from '@/scenario/keys';
import { getImageUrl } from '@/utils/s3';
import { useHeadSafe } from '@unhead/vue';
import { DateTime } from 'luxon';
import { computed, inject } from 'vue';

const scenarioGroupInject = inject(scenarioGroupData);
const scenarioGroup = scenarioGroupInject?.scenarioGroup;

const fields = computed(() => scenarioGroupInject?.fields.value);
const errors = computed(() => scenarioGroupInject?.errors.value);

const activeCardInject = inject(activeCardData);
const orgFeedInject = inject(orgFeedData);
const orgFeedEntries = computed(() => orgFeedInject?.feedEntries.value ?? []);

const cardId = 'scenario-group-organisation';
const cardLabel = fluent.$t('organisational-categorization');

function onDiscardChanges() {
    activeCardInject?.onLeaveEditMode();
    orgFeedInject?.onDiscardFeedChanges();
    scenarioGroupInject?.discardChanges();
}

function onSave() {
    scenarioGroupInject?.onUpdateScenarioGroup();
    orgFeedInject?.onSaveFeed();
    activeCardInject?.onLeaveEditMode();
}

useHeadSafe({
    title: () =>
        `${fluent.$t('organisational-structure')} - ${scenarioGroup?.value?.nameShort} - ${fluent.$t(
            'scenario-group',
        )}`,
});
</script>
