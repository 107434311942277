import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { RouteLocationRaw } from 'vue-router';
import { ExternalEvent, PhaseForm } from '../Timeline.types';
import {
    TimelineDayWithLayoutedAllocations,
    TimelineDayWithNotLayoutedAllocations,
    VisibleAllocationStart,
} from '../TimelineView.types';
import { insertInvisibleAllocations } from './continuingAllocationsLayout';

export const EXTERNAL_EVENTS_ROW_ID = 'external-events-row';

export function calculateExternalEventDays(
    externalEvents: ExternalEvent[],
    timelineStartDateTime: DateTime,
    durationInDays: number,
): TimelineDayWithLayoutedAllocations[] {
    if (externalEvents.length === 0) {
        return [];
    }

    let tempExternalEventDays: TimelineDayWithNotLayoutedAllocations[] = [];

    for (let dayIndex = 0; dayIndex < durationInDays; dayIndex++) {
        // Calculate form of events
        let eventStartsPerDay: {
            startDateTime: DateTime;
            label: string;
            variantLabel?: string;
            groupLabel: string;
            form: PhaseForm;
            totalDurationInDays: number;
            eventId: string;
            isEditable: boolean;
            linkTarget: RouteLocationRaw;
        }[] = [];
        const dateTime = timelineStartDateTime.plus({ days: dayIndex });

        const eventStartsOnThisDay = externalEvents.filter(
            (event) => event.startDateTime.diff(dateTime, 'days').days === 0,
        );

        eventStartsOnThisDay.forEach((event) => {
            if (event.cellSpan === 1) {
                // Single day event
                eventStartsPerDay = [
                    ...eventStartsPerDay,
                    {
                        startDateTime: dateTime,
                        label: event.label,
                        variantLabel: event.variantLabel,
                        groupLabel: event.groupLabel,
                        form: 'Single' as const,
                        totalDurationInDays: event.cellSpan,
                        eventId: event.id,
                        isEditable: event.isEditable,
                        linkTarget: event.linkTarget,
                    },
                ];
            } else {
                // Event spans across multiple days
                eventStartsPerDay = [
                    ...eventStartsPerDay,
                    {
                        startDateTime: dateTime,
                        label: event.label,
                        variantLabel: event.variantLabel,
                        groupLabel: event.groupLabel,
                        form: 'Start' as const,
                        totalDurationInDays: event.cellSpan,
                        eventId: event.id,
                        isEditable: event.isEditable,
                        linkTarget: event.linkTarget,
                    },
                ];
            }
        });

        tempExternalEventDays = [
            ...tempExternalEventDays,
            {
                isWorkDay: dateTime.weekday !== 6 && dateTime.weekday !== 7,
                conflictMarker: 'no-conflict',
                hasCreateButton: false,
                isFirstRow: true,
                allocationStarts: eventStartsPerDay.map((event) => {
                    return {
                        type: 'Visible-Allocation-Start',
                        id: event.eventId,
                        allocationType: 'None',
                        allocationVariantId: 'external-event',
                        allPhases: [
                            {
                                id: nanoid(),
                                startDateTime: dateTime,
                                cellSpan: event.totalDurationInDays,
                                type: 'ExternalEvent',
                                specialization: null,
                            },
                        ],
                        visibleCellSpan: event.totalDurationInDays,
                        label: event.label,
                        variantLabel: event.variantLabel,
                        groupLabel: event.groupLabel,
                        isLabelVisible: event.form === 'Start' || event.form === 'Single',
                        labelCellSpan: event.totalDurationInDays,
                        labelCellSpanType: event.form === 'Single' ? 'entire' : 'start-middle',
                        isEditable: false,
                        linkTarget: event.linkTarget,
                        startDateTime: event.startDateTime,
                        endDateTime: event.startDateTime.plus({ days: event.totalDurationInDays }),
                        variant: 'Solid',
                    } satisfies VisibleAllocationStart;
                }),
                rowId: EXTERNAL_EVENTS_ROW_ID,
                columnId: `column-${dayIndex}`,
                isFirstColumn: dayIndex === 0,
                startDateTime: dateTime,
            },
        ];
    }

    return insertInvisibleAllocations(tempExternalEventDays);
}

export function countExternalEventsPerDay(externalEventsPerDay: TimelineDayWithLayoutedAllocations[]): number {
    let max = 0;

    externalEventsPerDay.forEach((day) => {
        if (day.allocationStarts.length > max) {
            max = day.allocationStarts.length;
        }
    });

    return max;
}
