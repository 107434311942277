<template>
    <div>
        <HeaderCardsLayout v-if="scenarioGroup.id" variant="No-Logo">
            <template #headline-extra>
                <BadgeDropDown
                    :label="$t('status')"
                    class="text-base font-normal"
                    :current-value="getStatusBadgeCurrentValue()"
                    :values="getStatusBadgeItems()"
                    :status="getStatusBadgeStatus()"
                    @change-value="(value: string) => onStatusBadgeChange(value)"
                />
            </template>
            <template #headline>
                <div class="flex gap-2">
                    <ScenarioGroupIndex :index="scenarioGroup.index" :max-digits="5" />
                    <span class="text-gray-400 font-light">|</span>
                    {{ scenarioGroup.nameShort }}
                </div>
            </template>
            <template #breadcrumb>
                <Breadcrumb :headline="$t('breadcrumb')" :current-page="currentPageName">
                    <BreadcrumbLink :target="{ name: 'scenarios-overview' }">
                        <BrainIcon class="mr-1" />
                        {{ $t('scenarios') }}
                    </BreadcrumbLink>
                </Breadcrumb>
            </template>
            <template #buttons>
                <Button
                    variant="Danger-Strong"
                    size="Base"
                    :disabled-explanation="deleteScenarioGroupDisabledExplanation"
                    @click="onDeleteScenarioGroup()"
                >
                    <template #icon>
                        <DeleteForeverIcon />
                    </template>
                    {{ $t('delete') }}
                </Button>
                <Button
                    variant="Info-Strong"
                    size="Base"
                    :disabled-explanation="createScenarioDisabledExplanation"
                    @click="goToCreateScenario(scenarioGroup.id, scenarioGroup.nameShort, scenarioGroup.index)"
                >
                    <template #icon><PlusIcon /></template>
                    {{ $t('scenario-create') }}
                </Button>
            </template>
            <template #tab-navigation>
                <TabNavigation
                    :headline="$t('tab')"
                    :items="tabNavigationItems"
                    tab-panel-id="eventSeriesTabs"
                    :tabs-disabled-text="tabsDisabledText"
                    @focus-tab-panel="focusTabPanel"
                />
            </template>

            <template #body>
                <TabPanel :id="tabPanelId" ref="tabPanelRef" :headline="activeTabHeadline">
                    <RouterView />
                </TabPanel>
            </template>
        </HeaderCardsLayout>
    </div>
</template>
<script setup lang="ts">
import BadgeDropDown from '@/components/BadgeDropDown/BadgeDropDown.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import Button from '@/components/Button/Button.vue';
import BrainIcon from '@/components/Icon/BrainIcon.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import PlusIcon from '@/components/Icon/PlusIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import ScenarioGroupIndex from '@/components/ScenarioGroupIndex/ScenarioGroupIndex.vue';
import TabNavigation from '@/components/TabNavigation/TabNavigation.vue';
import TabPanel from '@/components/TabNavigation/TabPanel.vue';
import { useFeed } from '@/feed/composables/useFeed';
import { useActiveCard } from '@/forms/composables/useActiveCard';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed, provide, ref, watch } from 'vue';
import { useScenarioGroup } from '../composables/useScenarioGroup';
import { useScenarioGroupForm } from '../composables/useScenarioGroupForm';
import { useScenarioGroupTabs } from '../composables/useScenarioGroupTabs';
import { useStatusBadge } from '../composables/useStatusBadge';
import { activeCardData, feedData, orgFeedData, scenarioGroupData } from '../keys';
import { getScenarioGroupIndexString, goToCreateScenario } from '../scenario';

const props = defineProps<{ scenarioGroupId: string }>();
const fluent = useFluent();
const tabPanelRef = ref();
const tabPanelId = nanoid();

const scenarioGroupId = computed(() => props.scenarioGroupId);

const {
    activeCard,
    resetActiveCard,
    onEnterEditMode,
    onLeaveEditMode,
    showForm,
    editButtonDisabledMessage,
    getCardStatus,
    setCardStatus,
} = useActiveCard();

const {
    scenarioGroup,
    createScenarioDisabledExplanation,
    deleteScenarioGroupDisabledExplanation,
    refetch,
    onDeleteScenarioGroup,
} = useScenarioGroup(scenarioGroupId);

const { fields, formTouched, errors, onInput, onUpdateScenarioGroup, discardChanges } = useScenarioGroupForm(
    scenarioGroup,
    refetch,
    resetActiveCard,
);

const { getStatusBadgeCurrentValue, getStatusBadgeItems, getStatusBadgeStatus, handleStatusBadgeChange } =
    useStatusBadge(scenarioGroup);

// load changes feed

const changesFeedId = computed(() => scenarioGroup.value?.changes.id ?? '');
const {
    feedEntries: changesFeedEntries,
    hasUnsavedChanges: changesFeedHasUnsavedChanges,
    addFeedEntry: addChangesFeedEntry,
    createUserTextEntry: createChangesUserTextEntry,
    createCreatedAtEntry,
    createStatusChangeEntry,
} = useFeed(changesFeedId);

// load organisationalChanges feed

const organisationalChangesFeedId = computed(() => scenarioGroup.value?.organisationalChanges.id ?? '');
const {
    feedEntries: orgFeedEntries,
    hasUnsavedChanges: orgFeedHasUnsavedChanges,
    addFeedEntry: addOrgFeedEntry,
    createUserTextEntry: createOrgUserTextEntry,
    setUnsavedChanges,
    setText,
    createUnsavedFeedEntry,
    onSaveFeed,
    onDiscardFeedChanges,
} = useFeed(organisationalChangesFeedId);

watch(
    [formTouched, changesFeedHasUnsavedChanges, orgFeedHasUnsavedChanges],
    ([newFormTouched, newChanges, newOrgChanges]) => {
        if (newFormTouched || newChanges || newOrgChanges) {
            setCardStatus('Edit-Touched');
        } else {
            setCardStatus('Edit-Untouched');
        }
    },
);

// provide data and functions for child tabs

provide(scenarioGroupData, {
    scenarioGroup,
    fields,
    errors,
    onInput,
    discardChanges,
    onUpdateScenarioGroup,
});
provide(activeCardData, {
    activeCard,
    onEnterEditMode,
    onLeaveEditMode,
    showForm,
    editButtonDisabledMessage,
    getCardStatus,
});
provide(feedData, {
    feedEntries: changesFeedEntries,
    addFeedEntry: addChangesFeedEntry,
    createUserTextEntry: createChangesUserTextEntry,
    createCreatedAtEntry,
    createStatusChangeEntry,
});
provide(orgFeedData, {
    feedEntries: orgFeedEntries,
    addFeedEntry: addOrgFeedEntry,
    createUserTextEntry: createOrgUserTextEntry,
    setUnsavedChanges,
    setText,
    createUnsavedFeedEntry,
    onSaveFeed,
    onDiscardFeedChanges,
});

// current page code

const scenarioGroupNameShort = computed(() => scenarioGroup.value?.nameShort ?? '');
const scenarioGroupIndex = computed(() => scenarioGroup.value?.index ?? 0);

const currentPageName = computed(
    () => `${getScenarioGroupIndexString(scenarioGroupIndex.value)} | ${scenarioGroupNameShort.value}`,
);

function focusTabPanel() {
    tabPanelRef.value.focus();
}

const { tabNavigationItems, tabsDisabledText, activeTabHeadline } = useScenarioGroupTabs(activeCard);

async function onStatusBadgeChange(value: string) {
    if (scenarioGroup.value) {
        const oldStatus = scenarioGroup.value.status;
        createStatusChangeEntry(oldStatus, value);
        handleStatusBadgeChange(value);
    }
}

useHeadSafe({ title: () => `${scenarioGroupNameShort.value} - ${fluent.$t('scenarios')}` });
</script>
