import auth from '@/auth/auth';
import { EditableCardStatus } from '@/components/Card/types';
import {
    DeleteScenarioDocument,
    GetScenarioDocument,
    GetScenarioQuery,
    ScenarioDetailsFragment,
    UpdateScenarioDocument,
} from '@/generated/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref, Ref, toValue } from 'vue';
import { ScenarioSchema, ScenarioSchemaType } from '../forms/schemas';
import { goToScenarioOverview, gotToScenarioGroup } from '../scenario';
import { ScenarioErrors, ScenarioFields } from '../types';

function setEmptyErrors() {
    return {
        nameShort: [],
        nameLong: [],
        year: [],
        intention: [],
        description: [],
    };
}

function setEmptyFields() {
    return {
        nameShort: '',
        nameLong: '',
        year: 0,
        intention: '',
        description: '',
    };
}

function setEmptyScenario() {
    return {
        id: '',
        index: 0,
        nameShort: '',
        nameLong: '',
        intention: '',
        description: '',
        year: 0,
        status: '',
        createdAt: '',
        scenarioGroup: undefined,
        changes: {
            id: '',
            feedEntries: {
                nodes: [],
            },
        },
    };
}

export function useScenario(
    scenarioId: Ref<string>,
    setCardStatus: (status: EditableCardStatus) => void,
    resetActiveCard: () => void,
) {
    const scenario: Ref<ScenarioDetailsFragment> = ref(setEmptyScenario());

    const getScenario = useQuery(
        GetScenarioDocument,
        computed(() => ({ id: toValue(scenarioId) })),
    );
    const deleteScenario = useMutation(DeleteScenarioDocument);
    const updateScenario = useMutation(UpdateScenarioDocument);

    const isAdmin = auth.isAdmin();
    const fluent = useFluent();

    const schema = ScenarioSchema;

    const scenarioGroupId = computed(() => scenario.value?.scenarioGroup?.id ?? undefined);

    const fields: Ref<ScenarioFields> = ref(setEmptyFields());
    const errors: Ref<ScenarioErrors> = ref(setEmptyErrors());

    getScenario.onResult((result) => {
        const data = result.data?.scenario;
        if (data) {
            fields.value = {
                nameShort: data.nameShort,
                nameLong: data.nameLong,
                year: data.year,
                intention: data.intention,
                description: data.description,
            };
            scenario.value = structuredClone(data);
        }
    });

    async function onDeleteScenario() {
        if (!isAdmin) return;
        if (!scenario.value) return;
        if (!confirm(fluent.$t('confirm-delete-scenario', { name: scenario.value.nameShort }))) return;
        const response = await deleteScenario.mutate({ id: toValue(scenarioId) });
        if (response?.data) {
            if (scenarioGroupId.value) {
                gotToScenarioGroup(scenarioGroupId.value);
            } else {
                goToScenarioOverview();
            }
        }
    }

    function isFieldTouched(key: string) {
        const actualValue = scenario[key as keyof GetScenarioQuery['scenario']];
        const newValue = fields.value[key];
        return actualValue !== newValue;
    }

    function isFormTouched() {
        const fieldKeys = schema.keyof().options;
        if (fieldKeys.some((f) => isFieldTouched(f))) {
            setCardStatus('Edit-Touched');
        } else {
            setCardStatus('Edit-Untouched');
        }
    }

    function onInput(fieldData: { event: Event; fieldName: string }) {
        const { event, fieldName } = fieldData;
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const key = fieldName as keyof ScenarioFields;
        const errorKey = fieldName as keyof ScenarioErrors;
        const schemaKey = fieldName as keyof ScenarioSchemaType;
        const parse = schema.shape[schemaKey].safeParse(value);
        if (parse.success) {
            errors.value[errorKey] = [];
            fields.value[key] = parse.data ?? '';
        } else {
            errors.value[errorKey] = parse.error.format()._errors;
            fields.value[key] = value;
        }
        isFormTouched();
    }

    function discardChanges() {
        getScenario.refetch();
        resetActiveCard();
    }

    async function onUpdateScenario() {
        const fieldValues = fields.value;
        const parse = schema.safeParse(fieldValues);
        if (parse.success) {
            const params = { id: toValue(scenarioId), ...fieldValues };
            await updateScenario.mutate(params);
        }
    }

    return {
        scenario,
        fields,
        errors,
        onInput,
        discardChanges,
        onUpdateScenario,
        onDeleteScenario,
    };
}
