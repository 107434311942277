<template>
    <div v-if="scenario && activeCardInject && scenarioInject" class="space-y-4">
        <FlexibleCard :headline-id="cardId" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="activeCardInject.getCardStatus(cardId)"
                    :edit-button-disabled-explanation="activeCardInject.editButtonDisabledMessage(cardId)"
                    @enter-edit-mode="activeCardInject?.onEnterEditMode(cardId, cardLabel)"
                    @leave-edit-mode="activeCardInject?.onLeaveEditMode()"
                    @discard-changes="onDiscardChanges"
                    @save="onSave"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ cardLabel }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>
            <template #body>
                <ContentFormTransition>
                    <ScenarioMasterdataForm
                        v-if="activeCardInject.showForm(cardId) && fields && errors"
                        :fields="fields"
                        :errors="errors"
                        class="p-5"
                        @on-input="scenarioInject.onInput($event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('name-short')"
                            :text="valueToString(scenario.nameShort)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('name-long')"
                            :text="valueToString(scenario.nameLong)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('year')"
                            :text="valueToString(scenario.year?.toString())"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('intention')"
                            :text="valueToString(scenario.intention)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('description')"
                            :text="valueToString(scenario.description)"
                            :has-border-bottom="true"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>
<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { valueToString } from '@/forms/forms';
import ScenarioMasterdataForm from '@/scenario/forms/scenario/ScenarioMasterdataForm.vue';
import { activeCardData, scenarioData } from '@/scenario/keys';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { computed, inject } from 'vue';

const fluent = useFluent();

const scenarioInject = inject(scenarioData);
const scenario = computed(() => scenarioInject?.scenario.value);

const fields = computed(() => scenarioInject?.fields.value);
const errors = computed(() => scenarioInject?.errors.value);

const activeCardInject = inject(activeCardData);

const cardId = 'scenario-masterdata';
const cardLabel = fluent.$t('identifiers');

function onDiscardChanges() {
    activeCardInject?.onLeaveEditMode();
    scenarioInject?.discardChanges();
}

function onSave() {
    scenarioInject?.onUpdateScenario();
    activeCardInject?.onLeaveEditMode();
}

useHeadSafe({
    title: () =>
        `${fluent.$t('master-data')} - ${scenario.value?.nameShort} - ${scenario.value?.scenarioGroup
            ?.nameShort} - ${fluent.$t('scenarios')}`,
});
</script>
