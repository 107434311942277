<template>
    <div v-if="event" class="space-y-4">
        <FlexibleCard headline-id="organisation" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('organisation')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('organisation')"
                    @enter-edit-mode="
                        $emit('enterEditMode', { id: 'organisation', label: $t('organisational-categorization') })
                    "
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'organisation')"
                    @save="$emit('save', 'organisation')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('organisational-categorization') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <OrganisationForm
                        v-if="showForm('organisation')"
                        class="p-5"
                        :event="event"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('company-group')"
                            :text="valueToString(event.companyGroup)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('business-domain')"
                            :text="valueToString(event.businessDomain)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('department')"
                            :text="valueToString(event.department)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="teamStructure" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('teamStructure')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('teamStructure')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'teamStructure', label: $t('team-structure') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'teamStructure')"
                    @save="$emit('save', 'teamStructure')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('team-structure') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <TeamStructureForm
                        v-if="showForm('teamStructure')"
                        class="p-5"
                        :event="event"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('responsible')"
                            :text="valueToString(event.responsible)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('assistant')"
                            :text="valueToString(event.assistant)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('team-changes')"
                            :text="valueToString(event.teamChanges)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import OrganisationForm from '@/event/forms/event/OrganisationForm.vue';
import TeamStructureForm from '@/event/forms/event/TeamStructureForm.vue';
import { EventUpdate, EventUpdateErrors } from '@/event/types';
import { valueToString } from '@/forms/forms';
import { computed } from 'vue';

const props = defineProps<{
    event: EventUpdate;
    errors: EventUpdateErrors;
    activeCard: ActiveCard;
}>();

defineEmits<{
    (e: 'enterEditMode', v: { id: string; label: string }): void;
    (e: 'leaveEditMode'): void;
    (e: 'discardChanges', v: string): void;
    (e: 'save', v: string): void;
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
}>();

const activeCard = computed(() => props.activeCard);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard);
</script>
