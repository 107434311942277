<template>
    <div>
        <Label
            :label="props.label"
            :input-id="inputId"
            :help-button-text="props.helpButtonText"
            :is-required="props.required"
        />
        <div class="relative mt-1">
            <input
                :id="inputId"
                :value="value"
                :type="type"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :minLength="minLength"
                :maxLength="maxLength"
                :class="styling"
                :required="required"
                :placeholder="placeholder"
                :aria-invalid="errorMessages && errorMessages.length ? true : undefined"
                :aria-describedby="description ? `${inputId}-info` : undefined"
                :aria-errormessage="errorMessages && errorMessages.length ? `${inputId}-error` : undefined"
                spellcheck="false"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
                @blur="$emit('blur', $event)"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 gap-2">
                <ExclamationCircleIcon
                    v-if="errorMessages && errorMessages.length"
                    class="h-5 w-5 text-red-500"
                    aria-hidden="true"
                />
                <button
                    type="button"
                    :disabled="disabled"
                    :class="[
                        'text-gray-400 rounded',
                        disabled
                            ? 'cursor-not-allowed'
                            : 'focus:ring-primary-600 disabled:cursor-not-allowed hover:text-gray-500',
                    ]"
                    @click="toggleType"
                >
                    <VisibilityIcon v-if="type === 'password'" :title="$t('show-password')" class="w-5 h-5" />
                    <VisibilityOffIcon v-if="type === 'text'" :title="$t('hide-password')" class="w-5 h-5" />
                </button>
            </div>
            <div v-if="clearable" class="absolute inset-y-0 right-0 flex items-center pr-3">
                <XIcon class="h-5 w-5 text-gray-400" aria-hidden="true" @click.prevent="$emit('clear')" />
            </div>
        </div>
        <p v-if="description" :id="`${inputId}-info`" class="mt-2 text-sm text-gray-500">{{ description }}</p>
        <div
            v-if="errorMessages && errorMessages.length"
            :id="`${inputId}-error`"
            role="alert"
            class="mt-2 text-sm text-red-600"
        >
            <p v-for="message in errorMessages" :key="message">{{ message }}</p>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ExclamationCircleIcon, XIcon } from '@heroicons/vue/solid';
import { nanoid } from 'nanoid';
import { computed, ref } from 'vue';
import VisibilityIcon from '../Icon/VisibilityIcon.vue';
import VisibilityOffIcon from '../Icon/VisibilityOffIcon.vue';
import Label from './Label.vue';

type PasswordFieldProps = {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
    helpButtonText?: string;
    required?: boolean;
    placeholder?: string;
    errorMessages?: string[];
    autocomplete?: string;
    clearable?: boolean;
    minLength?: number;
    maxLength?: number;
};

const props = defineProps<PasswordFieldProps>();
defineEmits<{
    (e: 'input', v: Event): void;
    (e: 'change', v: Event): void;
    (e: 'blur', v: Event): void;
    (e: 'clear'): void;
}>();
const inputId = nanoid();

const type = ref('password');

const toggleType = () => {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
};

const styling = computed(() => {
    return [
        'block',
        'py-[0.5625rem]',
        'w-full',
        'rounded-md',
        'shadow-sm',
        'sm:text-sm',
        'disabled:cursor-not-allowed',
        'disabled:border-gray-200',
        'disabled:bg-gray-50',
        'disabled:text-gray-500',
        props.errorMessages && props.errorMessages.length
            ? 'focus:outline-none border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
            : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
    ];
});
</script>
