<template>
    <div v-if="project" class="space-y-4">
        <FlexibleCard headline-id="orgcat" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('orgcat')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('orgcat')"
                    @enter-edit-mode="
                        cardFunctions?.updateCard('orgcat', $t('organisational-categorization'), 'Edit-Untouched')
                    "
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectOrgSchema)"
                    @save="dataFunctions?.save(ProjectOrgSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('organisational-categorization') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectOrgForm v-if="showForm('orgcat')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('company-group')"
                            :text="valueToString(project.companyGroup)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('business-domain')"
                            :text="valueToString(project.businessDomain)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('department')"
                            :text="valueToString(project.department)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('organisational-changes')"
                            :text="valueToString(project.organisationalChanges)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { valueToString } from '@/forms/forms';
import { $t } from '@/plugins/fluent';
import ProjectOrgForm from '@/project/forms/project/ProjectOrgForm.vue';
import { activeCardData, activeCardFunctions, formFunctions, projectData } from '@/project/keys';
import { ProjectOrgSchema } from '@/project/schemas';
import { useHeadSafe } from '@unhead/vue';
import { inject, ref } from 'vue';

const data = inject(projectData);
const project = data?.project;

useHeadSafe({
    title: () =>
        `${$t('organisational-structure')} - ${project?.value.nameShort} - ${project?.value.series.nameShort} - ${$t(
            'project-wiki',
        )}`,
});

const dataFunctions = inject(formFunctions);
const cardFunctions = inject(activeCardFunctions);
const activeCard = inject(activeCardData);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard ?? ref(emptyActiveCard()));
</script>
