<template>
    <div v-if="project" class="space-y-4">
        <FlexibleCard headline-id="identifiers" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('identifiers')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('identifiers')"
                    @enter-edit-mode="cardFunctions?.updateCard('identifiers', $t('identifiers'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectIdentifiersSchema)"
                    @save="dataFunctions?.save(ProjectIdentifiersSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('project-base-data') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectIdentifiersForm v-if="showForm('identifiers')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('name-short')"
                            :text="valueToString(project.nameShort)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('name-long')"
                            :text="valueToString(project.nameLong)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('subtitle')"
                            :text="valueToString(project.subtitle)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('execution-year')"
                            :text="valueToString(project.executionYear?.toString())"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('description')"
                            :text="valueToString(project.description)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="typeDefinitions" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('typeDefinitions')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('typeDefinitions')"
                    @enter-edit-mode="
                        cardFunctions?.updateCard('typeDefinitions', $t('type-definition'), 'Edit-Untouched')
                    "
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectTypeDefSchema)"
                    @save="dataFunctions?.save(ProjectTypeDefSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('type-definition') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectTypeDefinitionForm v-if="showForm('typeDefinitions')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('project-category')"
                            :text="valueToString(project.category)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('project-type')"
                            :text="valueToString(project.type)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="disturbance" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('disturbance')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('disturbance')"
                    @enter-edit-mode="cardFunctions?.updateCard('disturbance', $t('disturbance'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectDisturbanceSchema)"
                    @save="dataFunctions?.save(ProjectDisturbanceSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('disturbance') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectDisturbanceForm v-if="showForm('disturbance')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('project-disturbance-noise')"
                            :text="valueToString(project.disturbanceNoise)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('project-disturbance-dust')"
                            :text="valueToString(project.disturbanceDust)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('project-disturbance-other')"
                            :text="valueToString(project.disturbanceOther)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { valueToString } from '@/forms/forms';
import { $t } from '@/plugins/fluent';
import ProjectDisturbanceForm from '@/project/forms/project/ProjectDisturbanceForm.vue';
import ProjectIdentifiersForm from '@/project/forms/project/ProjectIdentifiersForm.vue';
import ProjectTypeDefinitionForm from '@/project/forms/project/ProjectTypeDefinitionForm.vue';
import { activeCardData, activeCardFunctions, formFunctions, projectData } from '@/project/keys';
import { ProjectDisturbanceSchema, ProjectIdentifiersSchema, ProjectTypeDefSchema } from '@/project/schemas';
import { useHeadSafe } from '@unhead/vue';
import { inject, ref } from 'vue';

const data = inject(projectData);
const project = data?.project;

useHeadSafe({
    title: () =>
        `${$t('master-data')} - ${project?.value.nameShort} - ${project?.value.series.nameShort} - ${$t(
            'project-wiki',
        )}`,
});

const dataFunctions = inject(formFunctions);
const cardFunctions = inject(activeCardFunctions);
const activeCard = inject(activeCardData);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard ?? ref(emptyActiveCard()));
</script>
