<template>
    <div>
       <Label
            :label="props.label"
            :input-id="inputId"
            :help-button-text="props.helpButtonText"
            :is-required="props.required"
        />
        <div class="relative mt-1">
            <input
                :id="inputId"
                :value="value"
                type="datetime-local"
                :disabled="disabled"
                :class="styling"
                :required="required"
                :aria-invalid="errorMessages && errorMessages.length ? true : undefined"
                :aria-describedby="description ? `${inputId}-info` : undefined"
                :aria-errormessage="errorMessages && errorMessages.length ? `${inputId}-error` : undefined"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
                @blur="$emit('blur', $event)"
            />
            <div v-if="errorMessages && errorMessages.length" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-9">
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
        </div>
        <p
            v-if="description"
            :id="`${inputId}-info`"
            :class="[props.status === 'overridden' ? 'mt-2 text-sm text-indigo-600' : 'mt-2 text-sm text-gray-500']"
        >
            {{ description }}
        </p>
        <p v-if="errorMessages && errorMessages.length" :id="`${inputId}-error`" role="alert" class="mt-2 text-sm text-red-600">
            <div v-for="message in errorMessages" :key="message">{{ message }}</div>
        </p>
    </div>
</template>
<script setup lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/solid';
import { nanoid } from 'nanoid';
import { computed } from 'vue';
import Label from './Label.vue';

type DateTimeFieldProps = {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
    helpButtonText?: string;
    required?: boolean;
    errorMessages?: string[];
    status?: 'overridden';
};

const props = defineProps<DateTimeFieldProps>();
defineEmits<{ (e: 'input', v: Event): void; (e: 'change', v: Event): void; (e: 'blur', v: Event): void }>();
const inputId = nanoid();

const styling = computed(() => {
    return [
        'block',
        'py-[0.5625rem]',
        'w-full',
        'rounded-md',
        'shadow-sm',
        'sm:text-sm',
        'disabled:cursor-not-allowed',
        'disabled:border-gray-200',
        'disabled:bg-gray-50',
        'disabled:text-gray-500',
        props.errorMessages && props.errorMessages.length
            ? 'focus:outline-none border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
            : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
        {
            'focus:outline-none border-blue-600 text-blue-600 focus:ring-blue-600 focus:border-blue-600':
                props.status === 'overridden',
        },
    ];
});
</script>
