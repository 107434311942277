<template>
    <HeaderCardsLayout v-if="project" variant="No-Logo">
        <template #headline>{{ project.nameShort }}</template>
        <template #headline-extra>
            <BadgeDropDown
                :label="$t('project-series-status-label')"
                class="text-base font-normal"
                :current-value="getProjectStatusItemWithTooltip(project.status)"
                :values="getProjectStatusItems(project.status)"
                :status="BadgeStatus.state"
                @change-value="handleBadgeDropdown($event)"
            />
        </template>
        <template #breadcrumb>
            <Breadcrumb :headline="$t('breadcrumb')" :current-page="project.nameShort">
                <BreadcrumbLink :target="{ name: 'projects' }">
                    <ConstructionIcon class="mr-1" />
                    {{ $t('project-wiki') }}
                </BreadcrumbLink>
                <BreadcrumbLink v-if="project.series.id" :target="getProjectSeriesLink(project.series.id)">
                    {{ project.series.nameShort }}
                </BreadcrumbLink>
            </Breadcrumb>
        </template>

        <template #buttons>
            <Button
                variant="Danger-Strong"
                size="Base"
                :disabled-explanation="deleteDisabledExplanation"
                @click="deleteSeries"
            >
                <template #icon>
                    <DeleteForeverIcon />
                </template>
                {{ $t('delete') }}
            </Button>
        </template>

        <template #tab-navigation>
            <TabNavigation
                :headline="$t('tab')"
                :items="tabNavigationItems"
                tab-panel-id="eventSeriesTabs"
                :tabs-disabled-text="tabsDisabledText"
                @focus-tab-panel="focusTabPanel"
            />
        </template>

        <template #body>
            <TabPanel :id="tabPanelId" ref="tabPanelRef" :headline="activeTabHeadline">
                <RouterView />
            </TabPanel>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import BadgeDropDown, { BadgeDropDownValue } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import Button from '@/components/Button/Button.vue';
import ConstructionIcon from '@/components/Icon/ConstructionIcon.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import TabNavigation from '@/components/TabNavigation/TabNavigation.vue';
import TabPanel from '@/components/TabNavigation/TabPanel.vue';
import { DeleteProjectDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed, provide, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { activeCardData, activeCardFunctions, formFunctions, projectData } from '../keys';
import {
    createNavItems,
    getProjectSeriesLink,
    getProjectStatusItem,
    getProjectStatusItems,
    useProject,
} from '../project';
import { ProjectStatus } from '../types';

const toast = useToast();
const fluent = useFluent();
const isAdmin = auth.isAdmin();
const router = useRouter();

const props = defineProps<{ projectId: string }>();
const tabPanelRef = ref();
const tabPanelId = nanoid();

const projectId = computed(() => props.projectId);
const {
    project,
    errors,
    multilinks,
    handleFormField,
    handleMultilink,
    deleteMultilinkLink,
    isFormTouched,
    activeCard,
    updateCard,
    resetCard,
    discardChanges,
    save,
    updateStatus,
    updateStatusLoading,
    updateStatusError,
} = useProject(projectId);

useHeadSafe({ title: () => `${project.value.nameShort} - ${fluent.$t('project-wiki')}` });

provide(projectData, { project, errors, multilinks });
provide(formFunctions, { handleFormField, handleMultilink, save, deleteLink: deleteMultilinkLink, isFormTouched });
provide(activeCardFunctions, { updateCard, resetCard, discardChanges });
provide(activeCardData, activeCard);

const deleteProject = useMutation(DeleteProjectDocument);
async function deleteSeries() {
    if (!isAdmin) return;
    const name = project.value.nameShort ?? '';
    if (!confirm(fluent.$t('confirm-delete-project', { name }))) return;
    const seriesId = project.value.series.id;
    const res = await deleteProject.mutate({ id: props.projectId });
    if (res?.data) {
        toast.success(fluent.$t('delete-success-notification', { type: 'project', name }));
        router.replace(getProjectSeriesLink(seriesId));
    }
}

const deleteDisabledExplanation = computed(() => {
    return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
});

const tabs = [
    { route: 'project-details-tab', label: fluent.$t('master-data') },
    { route: 'project-overview-tab', label: fluent.$t('allocations') },
    { route: 'project-variants-tab', label: fluent.$t('project-variants') },
    { route: 'project-org-tab', label: fluent.$t('organisational-structure') },
    { route: 'project-appointment-tab', label: fluent.$t('appointment-and-location') },
    { route: 'project-links-tab', label: fluent.$t('links') },
];

const tabNavigationItems = reactive(createNavItems(tabs, activeCard));

function focusTabPanel() {
    tabPanelRef.value.focus();
}

const tabsDisabledText = computed(() => fluent.$t('tabs-disabled', { name: activeCard.value.label }));

const route = useRoute();

const activeTabHeadline = computed(() => {
    return fluent.$t('tab-panel', {
        activeTabName: tabNavigationItems.find((item) => item.routeName === route.name)?.label ?? '',
    });
});

const changeStatusDisabledExplanation = computed(() => {
    return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
});

const BadgeStates = {
    default: { state: 'Default' as const, tooltip: undefined },
    loading: { state: 'Loading' as const, tooltip: $t('status-loading') },
    retry: { state: 'Retry' as const, tooltip: $t('status-retry') },
    disabled: { state: 'Disabled' as const, tooltip: changeStatusDisabledExplanation.value },
};

const BadgeStatus = computed(() => {
    if (changeStatusDisabledExplanation.value) return BadgeStates.disabled;
    if (updateStatusLoading.value) return BadgeStates.loading;
    if (updateStatusError.value) return BadgeStates.retry;
    return BadgeStates.default;
});

function getProjectStatusItemWithTooltip(status: ProjectStatus): BadgeDropDownValue & { tooltip?: string } {
    const item = getProjectStatusItem(status);
    return {
        ...(item as BadgeDropDownValue),
        tooltip: BadgeStatus.value.tooltip,
    };
}

async function handleBadgeDropdown(id: string) {
    await updateStatus(id as ProjectStatus);
}
</script>
