<template>
    <div v-if="scenarioGroup && scenarioGroupInject" class="space-y-4">
        <HeadlessCard :invisible-headline="$t('changes')">
            <div class="p-4">
                <Feed>
                    <AddDateCommentEntry
                        @input="
                            (event: Event) => handleAddDateCommentEntryInput((event.target as HTMLInputElement).value)
                        "
                        @create-comment="(text: string) => onCreateComment(text)"
                    />
                    <template v-for="entry in feedEntries" :key="entry.id">
                        <DateCommentEntry
                            v-if="entry.type === 'user-text'"
                            :text="entry.text"
                            :image-url="getImageUrl(entry.createdBy?.icon?.original)"
                            :name="entry.createdByName"
                            :date-time="DateTime.fromISO(entry.createdAt)"
                            :is-editable="false"
                            has-unsaved-changes="None"
                        />
                        <StatusChangeEntry
                            v-if="entry.type === 'new-status'"
                            :new-status="$t(`scenario-group-status-${entry.newStatus}`)"
                            :previous-status="$t(`scenario-group-status-${entry.oldStatus}`)"
                            :date-time="DateTime.fromISO(entry.createdAt)"
                            :user-name="entry.createdByName"
                        />
                        <CreationDateEntry
                            v-if="entry.type === 'created-at'"
                            :user-name="entry.createdByName"
                            :date-time="DateTime.fromISO(entry.objectCreatedAt ?? '')"
                        />
                    </template>
                </Feed>
            </div>
        </HeadlessCard>
    </div>
</template>
<script setup lang="ts">
import HeadlessCard from '@/components/Card/HeadlessCard.vue';
import AddDateCommentEntry from '@/components/Feed/AddDateCommentEntry.vue';
import CreationDateEntry from '@/components/Feed/CreationDateEntry.vue';
import DateCommentEntry from '@/components/Feed/DateCommentEntry.vue';
import Feed from '@/components/Feed/Feed.vue';
import StatusChangeEntry from '@/components/Feed/StatusChangeEntry.vue';
import { feedData, scenarioGroupData } from '@/scenario/keys';
import { getImageUrl } from '@/utils/s3';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { computed, inject, ref } from 'vue';

const fluent = useFluent();

const scenarioGroupInject = inject(scenarioGroupData);
if (!scenarioGroupInject) {
    throw new Error('Missing injected ScenarioGroup data');
}
const scenarioGroup = computed(() => scenarioGroupInject.scenarioGroup.value);

const feedInject = inject(feedData);
if (!feedInject) {
    throw new Error('Missing injected feed data');
}

const feedEntries = computed(() => feedInject.feedEntries.value);
const createUserTextEntry = feedInject.createUserTextEntry;

const newEntry = ref('');

function handleAddDateCommentEntryInput(value: string) {
    newEntry.value = value;
}

function onCreateComment(text: string) {
    createUserTextEntry(text);
}

useHeadSafe({
    title: () =>
        `${fluent.$t('changes')} - ${scenarioGroup.value?.nameShort} - ${scenarioGroup.value?.nameShort} - ${fluent.$t(
            'scenarios',
        )}`,
});
</script>
