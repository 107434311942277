import { nanoid } from 'nanoid';
import {
    TimelineDayAllocation,
    TimelineDayWithLayoutedAllocations,
    TimelineDayWithNotLayoutedAllocations,
    VisibleAllocationStart,
} from '../TimelineView.types';

const ANY_INDEX = -1;

export function insertInvisibleAllocations(
    days: TimelineDayWithNotLayoutedAllocations[],
): TimelineDayWithLayoutedAllocations[] {
    const reservedRowsByRowIndex: Map<number, string> = new Map();
    const reservedRowsByAllocationId: Map<string, number> = new Map();
    const allocationEndDays: Map<string, number> = new Map();
    const daysWithInvisibleAllocations: TimelineDayWithLayoutedAllocations[] = Array(days.length).fill({
        allocations: [],
    });
    const remainingCellSpansInRow: Map<number, number> = new Map();

    // Run through all days and place the allocations in the correct in-cell rows
    for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
        let rowIndex = 0;
        let countVisibleAllocations = 0;

        const unplacedAllocationParts: Map<number, VisibleAllocationStart[]> = new Map();
        const placedAllocationParts: TimelineDayAllocation[] = [];

        // Clean up completed allocations
        for (const [allocationId, endDay] of allocationEndDays) {
            if (dayIndex >= endDay) {
                const rowIndex = reservedRowsByAllocationId.get(allocationId);
                if (rowIndex !== undefined) {
                    reservedRowsByRowIndex.delete(rowIndex);
                    reservedRowsByAllocationId.delete(allocationId);
                    remainingCellSpansInRow.delete(rowIndex);
                }
            }
        }

        // Count allocations that start on this day
        for (const allocation of days[dayIndex].allocationStarts) {
            if (allocation.type === 'Visible-Allocation-Start') {
                countVisibleAllocations++;
            }
        }

        // Count continuing allocations on this day
        for (const [allocationId] of reservedRowsByAllocationId) {
            const allocationEndDay = allocationEndDays.get(allocationId);

            if (allocationEndDay && allocationEndDay > dayIndex) {
                countVisibleAllocations++;
            }
        }

        // Count placeholder allocations needed on this day
        const maxRowIndex = Math.max(...Array.from(reservedRowsByRowIndex.keys()));
        if (maxRowIndex >= 0) {
            for (let i = 0; i <= maxRowIndex; i++) {
                if (!reservedRowsByRowIndex.has(i)) {
                    countVisibleAllocations++;
                }
            }
        }

        // Decrease the remaining cell spans in the rows
        const remainingCellSpans = remainingCellSpansInRow.get(rowIndex);
        if (remainingCellSpans !== undefined) {
            remainingCellSpansInRow.set(rowIndex, remainingCellSpans - 1);
        }

        // Reserve the correct row for allocations starting on this day
        for (const allocation of days[dayIndex].allocationStarts) {
            const reservedRowIndex = reservedRowsByAllocationId.get(allocation.id);
            if (reservedRowIndex !== undefined) {
                unplacedAllocationParts.set(reservedRowIndex, [allocation]);
            } else {
                // Track end day for allocations starting on this day
                allocationEndDays.set(allocation.id, dayIndex + allocation.visibleCellSpan);

                const anyIndexParts = unplacedAllocationParts.get(ANY_INDEX) ?? [];
                unplacedAllocationParts.set(ANY_INDEX, [...anyIndexParts, allocation]);
            }
        }

        // Place the allocations in the correct rows
        while (unplacedAllocationParts.size > 0) {
            // Make sure the row is not reserved (by an allocation started on a previous day) --> Start allocation on this day
            if (!reservedRowsByRowIndex.has(rowIndex)) {
                const unplacedStarts = unplacedAllocationParts.get(ANY_INDEX) ?? [];

                // The row is not reserved, so we can place the first allocation part in the row
                if (unplacedStarts.length > 0) {
                    const [allocationStart, ...remainingStarts] = unplacedStarts;

                    placedAllocationParts.push({ ...allocationStart });
                    if (unplacedStarts.length === 1) {
                        unplacedAllocationParts.delete(ANY_INDEX);
                    } else {
                        unplacedAllocationParts.set(ANY_INDEX, remainingStarts);
                    }

                    if (allocationStart.type === 'Visible-Allocation-Start' && allocationStart.visibleCellSpan > 1) {
                        remainingCellSpansInRow.set(rowIndex, allocationStart.visibleCellSpan - 1);
                    }

                    reservedRowsByRowIndex.set(rowIndex, allocationStart.id);
                    reservedRowsByAllocationId.set(allocationStart.id, rowIndex);
                } else {
                    placedAllocationParts.push({
                        type: 'Placeholder-Allocation',
                        id: `Invisible-${nanoid()}`,
                    });
                }
            } else {
                // The row is reserved, so we need to continue the allocation in the row
                const allocationId = reservedRowsByRowIndex.get(rowIndex);

                if (!allocationId) {
                    throw new Error(`Allocation (${allocationId}) not found, this should not happen`);
                }

                // Continue the allocation in the row, the timeline requires only the very first cell to be an actual allocation so this can be a placeholder
                placedAllocationParts.push({
                    type: 'Placeholder-Allocation',
                    id: `Invisible-${allocationId}-${nanoid()}`,
                });

                if (remainingCellSpans === 0) {
                    reservedRowsByRowIndex.delete(rowIndex);
                    reservedRowsByAllocationId.delete(allocationId);
                }
            }

            rowIndex++;
        }

        daysWithInvisibleAllocations[dayIndex] = {
            ...days[dayIndex],
            allocationStarts: placedAllocationParts,
            visibleAllocationCount: countVisibleAllocations,
        };
    }

    return daysWithInvisibleAllocations;
}
