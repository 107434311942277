import { GetMyUserDocument, MyUserFragment } from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed, Ref, ref } from 'vue';

function setEmptyUser() {
    return {
        id: '',
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        icon: undefined,
    };
}

export function useMyUser() {
    const currentUser: Ref<MyUserFragment> = ref(setEmptyUser());
    const getMyUser = useQuery(GetMyUserDocument);

    getMyUser.onResult((result) => {
        if (!result.loading) {
            if (result.errors) {
                throw new Error(`Error on loading MyUser: ${result.errors}`);
            }
            if (!result.data.myUser) {
                throw new Error('GetMyUser did not return data.');
            }
            const data = result.data.myUser;
            currentUser.value = structuredClone(data);
        }
    });
    const currentUserId = computed(() => currentUser.value?.id ?? '');
    const currentUserName = computed(() => currentUser.value?.name ?? '');
    const currentUserIcon = computed(() => currentUser.value?.icon?.original ?? '');

    return {
        currentUser,
        currentUserId,
        currentUserName,
        currentUserIcon,
    };
}
